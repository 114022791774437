// @flow
import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import Api from 'domain/api';
import flow from 'lodash/fp/flow';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { updateVendorBasedApprovalAction } from 'domain/approvals';
import { importVendorPreferencesAction } from 'domain/settings';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';

import { AgGridReact } from 'ag-grid-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ButtonExport from 'pages/configurations/company/components/ButtonExport';
import ButtonImport from 'pages/configurations/company/components/ButtonImport';
import AlertImportError from 'pages/configurations/company/components/AlertImportError';
import Stack from '@mui/material/Stack';
import toast from 'components/Toast';

import useAgGridProps from 'components/AgGrid/VendorBasedApprovals/useAgGridProps';
import useColumnTypes from 'components/AgGrid/VendorBasedApprovals/useAgGridColumnTypes';
import useFetchColumnDefs from 'components/AgGrid/VendorBasedApprovals/useFetchColumnDefs';
import useGridContext from 'components/AgGrid/VendorBasedApprovals/useGridContext';
import { addTooltipPropsForColDefs, addHeaderTooltipPropsForColDefs } from 'components/AgGrid/VendorBasedApprovals/helpers';
import { filterMapping } from 'components/AgGrid/VendorBasedApprovals/filterHelpers';
import { addFilters } from 'components/AgGrid/helpers/filterHelpers';
import { setExelModeFilter, GRID_FILTER_BUTTONS } from 'components/AgGrid/helpers'; 

import MultipleDropdownCellEditor from 'pages/configurations/company/pages/approvals/pages/vendorBasedApproval/components/MultipleDropdownCellEditor';
import MultipleDropdownCellRenderer from 'pages/configurations/company/pages/approvals/pages/vendorBasedApproval/components/MultipleDropdownCellRenderer';
import AgGridCustomTooltip from 'components/AgGrid/components/CustomTooltip';
import ApprovalsCellRenderer from './components/ApprovalsCellRenderer';
import ApprovalsCellEditor from './components/ApprovalsCellEditor';
import AgGridColumnHeader from 'components/AgGrid/components/ColumnHeader';
import CustomSetColumnFilter from 'components/AgGrid/components/CustomHeaderFilter/CustomSetColumnFilter/CustomSetColumnFilter';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

// inject order important for styles
import useAgGridGlobalTheme from 'components/AgGrid/hooks/useAgGridGlobalTheme';
import useAgGridStyles from 'components/AgGrid/VendorBasedApprovals/useAgGridStyles';

const VendorBasedApproval = () => {
  useAgGridGlobalTheme();
  useAgGridStyles();
  const features = useSelector(companyFeatureSetSelector);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const agGridDefaultProps = useAgGridProps();
  const columnTypes = useColumnTypes();
  const context = useGridContext();
  const gridRef = useRef(null);
  const [perPage, setPerPage] = useState(agGridDefaultProps.paginationPageSize);
  const [importError, setImportError] = useState(null);
  const [errorFileAvailable, setErrorFileAvailable] = useState(false);
  const [isImportBusy, setIsImportBusy] = useState(false);
  const [isBusyEditing, setIsBusyEditing] = useState(false);

  const { rawColumnDefs, rawColumnDefsIsBusy } = useFetchColumnDefs();


  const columnDefs = useMemo(
    // addFilters redefines 'headerComponentParams', functions modifying it must appear further in composition
    () => flow(
      addTooltipPropsForColDefs,
      addFilters(filterMapping),
      addHeaderTooltipPropsForColDefs,
      setExelModeFilter('windows', [GRID_FILTER_BUTTONS.APPLY, GRID_FILTER_BUTTONS.RESET]),
    )(rawColumnDefs),
    [rawColumnDefs],
  );

  const serverDatasource = useMemo(
    () => ({
      getRows: async (params) => {
        const { success, request, api } = params;
        const { startRow, endRow, filterModel, sortModel } = request;

        api.hideOverlay();

        try {
          const {
            data: { items, count },
          } = await Api.getVendorBasedApprovalsRows({
            data: {
              startRow,
              endRow,
              filterModel,
              sortModel,
            },
          });

          success({ rowData: items, rowCount: count });

          if (count === 0) {
            api.showNoRowsOverlay();
          }
        } catch (error) {
          console.log('[ERROR]: on fetch grid rows', error);
          success({ rowData: [], rowCount: 0 });
          api.showNoRowsOverlay();
        }
      },
    }),
    [],
  );

  const components = useMemo(
    () => ({
      customTooltip: AgGridCustomTooltip,
      multipleDropdownCellEditor: MultipleDropdownCellEditor,
      multipleDropdownCellRenderer: MultipleDropdownCellRenderer,
      approvalsCellRenderer: ApprovalsCellRenderer,
      approvalsCellEditor: ApprovalsCellEditor,
      agColumnHeader: AgGridColumnHeader,
      customSetColumnFilter: CustomSetColumnFilter,
    }),
    [],
  );

  const getRowID = useCallback(({ data }) => data.id, []);

  const onCellValueChanged = useCallback(
    ({ api, node, colDef, data, newValue, oldValue }) => {
      if (isEqual(newValue, oldValue)) return;

      const payload = {
        ids: [data.id],
        values: { [colDef.field]: data[colDef.field] },
      };

      if (node.selected) {
        api.forEachNode((n) => {
          if (n.selected && node.id !== n.id) {
            payload.ids.push(n.id);
          }
        });
      }

      setIsBusyEditing(true);

      new Promise((resolve, reject) => {
        dispatch(updateVendorBasedApprovalAction({ data: payload, resolve, reject }));
      })
        .then((response) => {
          api.applyServerSideTransaction({ update: response });
        })
        .finally(() => {
          setIsBusyEditing(false);
        });
    },
    [dispatch],
  );

  const onPaginationChanged = useCallback(({ newPageSize, api }) => {
    if (newPageSize) {
      const size = api.paginationGetPageSize();

      setPerPage(size);
    }
  }, []);

  const onCloseAlert = useCallback(() => {
    setImportError(null);
    setErrorFileAvailable(false);
  }, []);

  const onImportFile = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];

      if (file) {
        setIsImportBusy(true);

        new Promise((resolve, reject) => {
          dispatch(importVendorPreferencesAction({ file, sectionName: 'approval_flow', resolve, reject }));
        })
          .then(() => {
            gridRef.current?.api.refreshServerSide({ purge: true });
            onCloseAlert();

            toast.success(
              formatMessage({
                id: 'toast.vendorPreferences.import.success',
                defaultMessage: 'Import successful',
              }),
            );
          })
          .catch((data) => {
            const errorMessage = data?.response?.data?.message;
            if (data?.response?.status === 406) {
              setErrorFileAvailable(true);
            }
            if (errorMessage) {
              setImportError(errorMessage);
              toast.error(errorMessage);
            }
          })
          .finally(() => {
            setIsImportBusy(false);
          });
      }
    },
    [dispatch, formatMessage, onCloseAlert],
  );

  return (
    <Box className="ag-theme-material" display="flex" flexDirection="column" height="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight={500} mb={2}>
          {formatMessage({
            id: 'configurations.company.approvals.vendorBasedApproval.title',
            defaultMessage: 'Approval Flow',
          })}
        </Typography>
        {features.get('import_export_vendor_prefs_file', false) && (
          <Stack direction="row" spacing={1.5} ml="auto" mb={2}>
            <ButtonImport onImportFile={onImportFile} isBusy={isImportBusy} />
            <ButtonExport sectionName="approval_flow" />
          </Stack>
        )}
      </Stack>
      {importError && (
        <AlertImportError
          onClose={onCloseAlert}
          errorKey={errorFileAvailable ? 'import_file_has_errors' : ''}
          message={importError}
          sectionName="approval_flow"
        />
      )}

      <CircularProgressWithBackdrop isOpen={isBusyEditing} />
      {rawColumnDefsIsBusy && <LinearProgress />}
      {!rawColumnDefsIsBusy && (
        <AgGridReact
          {...agGridDefaultProps}
          ref={gridRef}
          serverSideDatasource={serverDatasource}
          columnTypes={columnTypes}
          columnDefs={columnDefs}
          components={components}
          context={context}
          getRowId={getRowID}
          onCellValueChanged={onCellValueChanged}
          onPaginationChanged={onPaginationChanged}
          cacheBlockSize={perPage}
        />
      )}
    </Box>
  );
};

export default VendorBasedApproval;
