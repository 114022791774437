// @flow
import React, { useState, useCallback, memo, useRef, useEffect, useMemo, useContext } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';
import LinearProgress from '@mui/material/LinearProgress';
import useQuery from 'hooks/useQuery';
import CompanyContext from 'pages/company/CompanyContext';
import { getNestedCategory } from 'domain/categories/helpers';
import VirtualizedList from 'components/AgGrid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedList';
import { AgGridFilterDropdown } from 'components/AgGrid/components/CustomHeaderFilter/CustomSetColumnFilter/StyledComponents';
import { type TFilterRowOptions } from 'components/AgGrid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedRow';
import { type TGridApi, type TColumnDefs } from 'pages/company/Grid/types.js.flow';
import { createTranslationsList } from 'components/AgGrid/helpers/translations';
import { debounce } from '@mui/material/utils';

type TModel =
  | {
      type: string,
      values: TFilterRowOptions,
    }
  | undefined;

type TCustomSetColumnFilter = {
  model: TModel | null | undefined,
  onModelChange: (model: TModel | null) => void,
  boolean: boolean,
  colDef: TColumnDefs,
  options: Array<{}>,
  context: {|
    autocompleteApiInstance: (data: {}) => Promise<{}>,
    fetchParams: {}, // is not dynamicaly updated once context changes
    additionalSetFilterPayload?: {| vendor_id: string |},
  |},
  api: TGridApi,
};

const CustomSetColumnFilter: React$StatelessFunctionalComponent<TCustomSetColumnFilter> = (props) => {
  const {
    model, // ag-grid props
    onModelChange, // ag-grid props
    boolean = false, // passed by b-end in filterParams to identify field as boolean with no 'Contains' dropdown
    colDef: { field }, // // ag-grid props, type static_select | select
    options, // passed by b-end in filterParams
    context: { autocompleteApiInstance, additionalSetFilterPayload },
    api: { getFilterModel },
  } = props;

  const params = useParams();
  const category = getNestedCategory(params);
  const query = useQuery();
  const { companyType } = useContext(CompanyContext);
  const isConfidential = companyType === 'confidential';
  const [isBusy, setIsBusy] = useState(false);
  const [values, setValues] = useState([]);
  const [containsFilterType, setContainsFilterType] = useState('contains');
  const containerRef = useRef(null); // dropdown parent element
  const unappliedModelRef = useRef(model?.values || []);
  const [searchValue, setSearchValue] = useState('');
  const listRef = useRef();
  const [closeFilter, setCloseFilter] = useState();
  const inputRef = useRef(null);
  const intl = useIntl();
  const { formatMessage } = intl;
  const filterTranslations = useMemo(() => createTranslationsList(intl), [intl]);
  // getting navigation params inside component as those are not updated when passed as grid context
  // other params are still passed as grid context because static during grid lifecycle
  const fetchParams = useMemo(() => ({
    isConfidential,
    category,
    ...query,
  }), [isConfidential, category, query]);
  
  const filterOptions = useMemo(
    () => [
      {
        label: filterTranslations.contains,
        value: 'contains',
      },
      {
        label: filterTranslations.notContains,
        value: 'notContains',
      },
    ],
    [filterTranslations],
  );

  const withoutBlanks = (list) => list.filter((option) => option.id !== '_blank');

  const [currentOptions, setCurrentOptions] = useState(options || []);

  const [filteredOptions, setFilteredOptions] = useState([]);

  const allSelected = useMemo(
    () =>
      filteredOptions.every(({ id }) => values.map((item) => item.id).includes(id)) &&
      values.length !== 0 &&
      filteredOptions.length !== 0,
    [filteredOptions, values],
  );

  const onSelectAll = useCallback(() => {
    // @to-do if we  want unchecking select all to uncheck only filtered values, we must unckeck filteredOptions only
    // instead of [];
    const selected = allSelected ? [] : filteredOptions;
    setValues(selected);
  }, [filteredOptions, setValues, allSelected]);

  useEffect(() => {
    // @to-do no need for concat here
    const newValues = model?.values.concat() || [];
    // unappliedModelRef - initial model, used for reset to previous state on detach
    unappliedModelRef.current = model;

    if (newValues.length > 0) {      
      setValues(newValues);
    }

    // selectAll in case filter was deleted from global filter chip bar
    // in case filter has been opened before we have options loaded and must set them all selected here
    // as 'fetch method' will not be triggered (loaded options indicate it was triggered before)
    // @to-do check if this can be ommited and set in 'fetch' function
    if ((model === null || model === undefined) && values?.length && currentOptions?.length) {
      setValues(currentOptions);
    }

    if (model?.type) {
      setContainsFilterType(model?.type);
    }
    
    // values and currentOptions shouldn't be dependency as it mustnt trigger effect. At the time its triggered
    // we have correct values and options
  }, [model]);

  const fetch = useCallback(async () => {
    try {
      setIsBusy(true);

      const filterModel = getFilterModel();
      const {
        data: { options: list },
        // $FlowFixMe
      } = await autocompleteApiInstance({
        data: {
          field,
          // eslint-disable-next-line camelcase
          search_model: {
            // for document grid we add fetchParams from context to request
            // for insights grid we add additionalSetFilterPayload from context to request
            //
            // additionalSetFilterPayload holds vendor_id
            // fetchParams holds all search params from url
            // if search applied on workspace
            //
            // either additionalSetFilterPayload or fetchParams are present in context
            // but not both
            // @to-do change to single paramener, no need to diverse so far
            ...(additionalSetFilterPayload || fetchParams),
            filterModel,
          },
        },
      });
      
      setCurrentOptions(list);
      // if no values provided default filter state should be 'all selected'
      // in case of search term provided 'all selected' is not considered default state
      if (
        (!model || model.values?.length === 0) &&
        searchValue?.length === 0 && // possible issue if other filter is updated and affects options list extending options
        (unappliedModelRef === null || !unappliedModelRef.current)
      ) {

        setValues(list);
      }
      setIsBusy(false);

      return list;
    } catch (error) {
      console.log('ERROR: fetching column filter data', error);
      setIsBusy(false);
      return [];
    }
  }, [field, fetchParams, autocompleteApiInstance, additionalSetFilterPayload, model, searchValue, getFilterModel]);

  const afterGuiAttached = useCallback((params) => {
    fetch().then();
    if (!params || !params.suppressFocus) {
      // Focus the input element for keyboard navigation.
      // Can't do this in an effect,
      // as the component is not recreated when hidden and then shown again
      inputRef.current?.focus();
    }

    if (params) {
      const { hidePopup } = params;

      setCloseFilter(() => hidePopup);
    }

    if (listRef.current) {
      listRef.current.scrollToItem(0, 'smart');
    }
  }, [fetch]);

  // register filter handlers with the grid
  useGridFilter({
    afterGuiAttached,
  });

  const onFilterTypeChanged = useCallback(
    ({ target: { value } }) => {
      setContainsFilterType(value);
    },
    [setContainsFilterType],
  );

  const onChangeInput = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchValue(value);
    },
    [setSearchValue],
  );

  const debouncedOnChangeInout = useMemo(() => debounce(onChangeInput, 250), [onChangeInput]);

  const onClickItem = useCallback(
    (option) => {
      const existIndex = values.findIndex((value) => value.id === option.id);
      if (existIndex > -1) {
        setValues(values.filter(({ id }) => id !== option.id));
      } else {
        setValues([...values, option]);
      }
    },
    [values],
  );

  // model has chages after filter popup opened
  const modelDirty = (currentModel, unAppliedModel) => !isEqual(currentModel, unAppliedModel);
  // filter has values curently applied for filtering
  const previousFilterInitializedWithValues = unappliedModelRef?.current?.values;
  // every possible option selected including ones that are filtered out. respects blanks option that can be excluded
  // in case search applied
  // @to-do check that values and options are equal by value but not length only
  const allPossibleValuesSelected = useMemo(
    () => {
      const mapper = ({ id }) => id;
      const optionIDs = currentOptions.map(mapper);
      // we must count for values that are available in current options list only.
      // if user navigates from another category he might have values not presented in option list
      const valueIDs = values.map(mapper).filter((value) => optionIDs.includes(value));

      const areEqual = !searchValue ?
        optionIDs.every((id) => valueIDs.includes(id)) && valueIDs.length === optionIDs.length :
        withoutBlanks(currentOptions).every(({ id }) => valueIDs.includes(id)) && valueIDs.length === withoutBlanks(currentOptions).length;

      return values.length > 0 && areEqual;
    },
    [searchValue, values, currentOptions],
  );

  // selected values visible after options filtering applied
  const filteredValues = useMemo(() => {
    const filteredIds = filteredOptions.map(({ id }) => id);
    const filteredValuesId = values.map(({ id }) => id).filter((id) => filteredIds.includes(id));
    return values.filter(({ id }) => filteredValuesId.includes(id));
  }, [filteredOptions, values]);

  const applyDisabled = searchValue.length > 0 && !filteredValues?.length;

  const onApply = useCallback(() => {
    const currentModel = { values, type: containsFilterType, filterType: 'set' };
    // apply model when changes is detected
    if (modelDirty(currentModel, unappliedModelRef.current)) {
      // if no search, standard logic applies
      if (!searchValue.length) {
        // apply new filter if values changed but still not all selected
        if (values.length > 0 && !allPossibleValuesSelected) {
          // if anything selected but not all selected
          onModelChange({
            filterType: 'set',
            type: containsFilterType,
            values,
          });
          // reset filter in case all selected and filter to reset previously existed
        } else if (previousFilterInitializedWithValues) {
          // if all deselected or all selected but previously filter was applied - clean filter
          onModelChange(null);
        }

        // if filter applied, nullify unuplied changes to prevent them from rendering on next filter open
        unappliedModelRef.current = null;
      } else if (filteredValues.length > 0) {
        // if search applied, send filtered selected values only
        onModelChange({
          filterType: 'set',
          type: containsFilterType,
          values: filteredValues,
        });
        // if filter applied, nullify unuplied changes to prevent them from rendering on next filter open
        unappliedModelRef.current = null;
      }
    }

    closeFilter?.();
  }, [
    onModelChange,
    closeFilter,
    containsFilterType,
    values,
    allPossibleValuesSelected,
    previousFilterInitializedWithValues,
    searchValue,
    filteredValues,
  ]);

  // reduce options list once search applied
  useEffect(() => {
    const applicator = searchValue?.length > 0 ? withoutBlanks : (x) => x;
    const list = applicator(
      currentOptions.filter(({ label }) => label.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1),
    );
    setFilteredOptions(list);
  }, [searchValue, currentOptions]);

  return (
    <Box flex="1">
      <form className="ag-filter-wrapper ag-focus-managed">
        <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper">
          <Box ref={containerRef} className="ag-set-filter" sx={{ margin: '10px' }}>
            {!boolean &&
              <AgGridFilterDropdown
                onChange={onFilterTypeChanged}
                value={containsFilterType}
                MenuProps={{
                  container: containerRef.current,
                  disablePortal: true,
                }}
              >
                {filterOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </AgGridFilterDropdown>
            }
          </Box>
          <div className="ag-set-filter">
            <Box
              role="presentation"
              className="ag-labeled ag-label-align-left ag-text-field ag-input-field"
              sx={{ marginLeft: '0px', marginRight: '10px' }}
            >
              <div
                className="ag-input-field-label ag-label ag-hidden ag-text-field-label"
                aria-hidden="true"
                role="presentation"
              />
              <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
                <CheckboxBase checked={allSelected} onChange={onSelectAll} />
                <input
                  ref={inputRef}
                  className="ag-input-field-input ag-text-field-input"
                  type="text"
                  tabIndex={0}
                  placeholder="Search"
                  onChange={debouncedOnChangeInout}
                />
              </div>
            </Box>
            {isBusy && <LinearProgress />}

            {searchValue && filteredOptions.length === 0 && <Box textAlign="center">No matches.</Box>}

            <VirtualizedList ref={listRef} options={filteredOptions} values={values} onClickItem={onClickItem} />
          </div>
        </div>
        <div className="ag-filter-apply-panel">
          <button
            type="submit"
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            onClick={onApply}
            disabled={applyDisabled}
          >
            {formatMessage({ id: 'button.ok' })}
          </button>
          <button
            type="button"
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            onClick={closeFilter}
          >
            {formatMessage({ id: 'button.cancel' })}
          </button>
        </div>
      </form>
    </Box>
  );
};

export default memo(CustomSetColumnFilter);
