// @flow
import { useMemo } from 'react';
import Api from 'domain/api';

const useGridContext = () =>
  useMemo(
    () => ({
      autocompleteApiInstance: Api.getVendorBasedApprovalsFilterListByField,
    }),
    [],
  );

export default useGridContext;
