// @flow
import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';

import { createApproverAction } from 'domain/approvals/actions';
import { approversSelector } from 'domain/approvals/selectors';

import useApprovals, { filterOptions, messages } from 'hooks/approval/useApprovals';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from 'pages/components/ApproversAutocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import ListSubheader from '@mui/material/ListSubheader';
import Approver from 'pages/components/Approver';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { alpha, styled } from '@mui/material/styles';

import elements from 'components/elements';

const groupsOrder = ['clients', 'accountants'];

type TAddApproverDialog = {
  open: boolean,
  handleClose: () => void,
  title: string,
};

const SMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

const MenuList = styled('ul')(() => ({ padding: 0 }));

const mapStateToProps = (state) => ({
  approvers: approversSelector(state),
});

const AddApproverDialog: React$StatelessFunctionalComponent<TAddApproverDialog> = ({ open, handleClose, title }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [selectedUser, setSelectedUser] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { approvers } = useSelector(mapStateToProps);
  const { approvers: users } = useApprovals([], groupsOrder, false);

  const options = useMemo(
    () => users.filter((user) => !approvers.some((approver) => user.id === approver.username)),
    [approvers, users],
  );

  const handleChange = useCallback((e, value) => {
    setSelectedUser(value.length ? [value.at(-1)] : value);
  }, []);

  const handleCreate = useCallback(async () => {
    setIsSubmitting(true);
    new Promise((resolve, reject) => {
      const payload = {
        resolve,
        reject,
        username: selectedUser[0].id,
      };
      dispatch(createApproverAction(payload));
    }).then(() => {
      handleClose();
    });
  }, [dispatch, handleClose, selectedUser]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={title}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      draggable={false}
    >
      <DialogContent>
        <Autocomplete
          fullWidth
          margin="normal"
          disabled={isSubmitting}
          data-element={elements.popup.approvers.selectUser}
          options={options}
          groupBy={(option) => option.group}
          value={selectedUser}
          onChange={handleChange}
          getOptionLabel={(o) => o.id}
          filterOptions={filterOptions}
          sx={{ marginTop: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="user"
              autoFocus
              label={formatMessage({
                id: 'configurations.company.approvals.list.dialog.form.user.placeholder',
                defaultMessage: 'Select user',
              })}
            />
          )}
          renderOption={(params, option, state) => (
            <SMenuItem {...params} selected={state.selected} data-element={elements.popup.approvers.option}>
              <ListItemText sx={{ mr: 1 }}>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Avatar alt={option.fullName} src={option.picture} sx={{ width: 24, height: 24 }} />
                  <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {option.fullName}
                    <br />({option.id})
                  </Box>
                </Stack>
              </ListItemText>
              {state.selected && (
                <ListItemIcon>
                  <Check sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
              )}
            </SMenuItem>
          )}
          renderGroup={(params) => (
            <Box key={params.key} data-element={`${elements.popup.approvalGroups.listOf}.${params.group}`}>
              <ListSubheader sx={{ top: '-8px', lineHeight: '40px', color: alpha(theme.palette.common.black, 0.38) }}>
                <Typography variant="overline" color="primary">
                  {formatMessage(messages[params.group], { count: params.children.length })}
                </Typography>
              </ListSubheader>
              <MenuList>{params.children}</MenuList>
            </Box>
          )}
          renderTags={(selected) =>
            selected.map(({ fullName, picture, id }) => (
              <Approver
                key={id}
                avatar={<Avatar alt={fullName} src={picture} />}
                label={fullName}
                variant="outlined"
                hasArrow={false}
                sx={{ maxWidth: '100%' }}
              />
            ))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-element={elements.popup.approvers.cancel}>
          {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleCreate}
          data-element={elements.popup.approvers.save}
          loading={isSubmitting}
          disabled={!selectedUser.length}
        >
          {formatMessage({ id: 'button.create', defaultMessage: 'Create' })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddApproverDialog;
