// @flow
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { localeSelector } from 'domain/env';
import download from 'lib/download';
import { useApiToken } from 'lib/apiTokenKeeper';
import Api from 'domain/api';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

type TAlertImportError = {
  onClose: () => void,
  message: string,
  errorKey?: string,
  sectionName?: string,
};

const AlertImportError: React$StatelessFunctionalComponent<TAlertImportError> = ({
  onClose,
  message = '',
  errorKey = '',
  sectionName,
}) => {
  const dokkaToken = useApiToken();
  const { companyId } = useParams();
  const language = useSelector(localeSelector);
  const endpoint = sectionName ? Api.importCorruptedVendorPreferencesNew : Api.importCorruptedVendorPreferences;

  const downloadFile = useCallback(async () => {
    await download(endpoint({ dokkaToken, company_id: companyId, language, sectionName }));
  }, [dokkaToken, companyId, language, endpoint, sectionName]);

  return (
    <Alert
      sx={{ mb: 1, alignItems: 'center' }}
      severity="error"
      onClose={onClose}
      action={
        <Stack direction="row" alignItems="center" spacing={1.5}>
          {errorKey === 'import_file_has_errors' && (
            <Button size="small" color="error" onClick={downloadFile}>
              <FormattedMessage id="button.download.file.errors" defaultMessage="Download errors file" />
            </Button>
          )}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      }
    >
      {message}
    </Alert>
  );
};

export default AlertImportError;
