/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
// @flow
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { List } from 'immutable';
import { connect, Dispatch } from 'react-redux';
import { compose } from 'redux';
import { filterTags, getSystemUser } from 'lib/helpers';
import * as ACL from 'domain/restriction';
import { organizationUsersForConfidentialDocSelector } from 'domain/organizationUser/selectors';
import { documentGet } from 'domain/documents/documentsActions';
import {
  createApprovalAction,
  removeApprovalAction,
  changeOrderApprovalAction,
  submitApprovalAction,
  userEnhancedApprovalsListSelector,
  approvalsStatusAsBoolSelector,
  approvalsEditableSelector,
  activeGroupNameEnhancedSelector,
  getApprovalGroupsAction,
  activeAdvancedGroupNameEnhancedSelector,
  userEnhancedApprovalsLevelsListSelector,
  approvalActiveAdvancedGroupIdSelector,
  addAdvancedApprovalGroupAction,
  removeAdvancedApprovalGroupAction,
} from 'domain/approvals';
import { approvalGroupListSelector } from 'domain/approvals/selectors';

import { toggleAFPanelAction } from 'domain/ui';
import { isDocumentScheduledApprovalSelector } from 'domain/documents';
import { chatAllUsersSelector } from 'domain/chat/chatSelector';
import type {
  UserEnhancedApprovalRecord,
  ApprovalRecordList,
  ApprovalLevelsWithUserData,
} from 'domain/approvals/types.js.flow';
import { companyApprovalsFeatureEnabledSelector } from 'domain/companies/companiesSelector';
import type { TLabelOptions } from 'labels/type.js.flow';
import type { ChatUserRecords, ChatUserRecord } from 'domain/chat/types.js.flow';

import cx from 'classnames';
import { withStyles } from '@mui/styles';
import { withApprovals } from './helpers';
import withConfirm from 'hoc/withConfirm';
import sheet from './sheet';
import identity from 'lodash/identity';
import memoizeOne from 'memoize-one';

import SortableList from './dnd/SortableList';
import Autocomplete from 'pages/components/ApproversAutocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { filterOptions, messages } from 'hooks/approval/useApprovals';
import Avatar from '@mui/material/Avatar';
import ApprovalsAvatarGroup from 'pages/components/ApprovalsAvatarGroup';
import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Approver from 'pages/components/Approver';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import ArrowButton from '../ArrowButton';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import Divider from '@mui/material/Divider';

import elements from 'components/elements';
import ApproverLevels from 'pages/components/ApproverLevels';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';

const MenuList = styled('ul')(() => ({ padding: 0 }));
const EndEdgeWrapper = styled(Stack)(() => ({ minWidth: 90 }));
const LockedModalContent = styled('div')(() => ({ textAlign: 'start' }));
const LockedModalList = styled('ol')(({ theme }) => ({
  listStyleType: 'decimal',
  textAlign: 'start',
  margin: theme.spacing(2, 0, 2, 4),
}));

export type Tag = {|
  tag: string,
  status?: string,
  picture?: string,
|};

type Props = {
  classes: {
    [key: string]: string,
  },
  intl: IntlShape,
  tags: List<UserEnhancedApprovalRecord>,
  documentId: string,
  companyId: string,
  general?: boolean,
  isGranted: (l: number | number[]) => boolean,
  createApproval: typeof createApprovalAction,
  removeApproval: typeof removeApprovalAction,
  createAdvancedApprovalGroup: typeof addAdvancedApprovalGroupAction,
  removeAdvancedApprovalGroup: typeof removeAdvancedApprovalGroupAction,
  changeOrderApproval: typeof changeOrderApprovalAction,
  submitApproval: typeof submitApprovalAction,
  getApprovalGroups: typeof getApprovalGroupsAction,
  refreshDoc: Dispatch<typeof documentGet>,
  toggleAFPanel: Dispatch<typeof toggleAFPanelAction>,
  organizationUsers: ChatUserRecords,
  isFlowActive: boolean,
  isEnabledApprovals: boolean,
  isEditableApprovals: boolean,
  theme: { labelOptions: TLabelOptions },
  onActivateApprovalsFlow: () => void,
  isScheduledApproval: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  setIsChangeApprovals: () => void,
  setIsEdit?: () => void,
  addTempApproval?: () => void,
  removeTempApproval?: () => void,
  approvalGroups?: [],
  activeGroupName?: string,
  activeAdvancedGroupName?: string,
  levels: ApprovalLevelsWithUserData,
  activeAdvancedGroupId: string,
};

type State = {
  open: boolean,
  active: ?ChatUserRecord,
  overNode: ApprovalRecordList<number> | null,
};

class Approval extends React.Component<Props, State> {
  wrapper: ?HTMLElement;

  approvalsLimit = 8;

  constructor() {
    super();

    this.state = {
      open: false,
      overNode: null,
    };
  }

  onToggle = () => {
    const { isScheduledApproval, toggleAFPanel, onClose = identity, onOpen = identity } = this.props;
    const { open } = this.state;

    const cb = open ? onClose : onOpen;
    cb();

    if (!isScheduledApproval) {
      toggleAFPanel(!open);
      this.setState({ open: !open });
    }
  };

  onChange = (event, value, reason, details) => {
    details.option.levels ? this.onAddAdvancedGroup(details.option.id) : this.onSelectSuggestion(details.option);
  };

  onChangedApprovals = () => {
    const { refreshDoc, documentId, companyId, setIsChangeApprovals } = this.props;

    setIsChangeApprovals();
    refreshDoc({ documentId, companyId, isLoader: false });
  };

  onAddAdvancedGroup = (id) => {
    const { createAdvancedApprovalGroup } = this.props;
    return new Promise((resolve, reject) => createAdvancedApprovalGroup({ id, resolve, reject })).then(() => {
      this.onChangedApprovals();
    });
  };

  onRemoveAdvancedGroup = () => {
    const { removeAdvancedApprovalGroup, activeAdvancedGroupId } = this.props;
    return new Promise((resolve, reject) =>
      removeAdvancedApprovalGroup({ id: activeAdvancedGroupId, resolve, reject }),
    ).then(() => {
      this.onChangedApprovals();
    });
  };

  onTagsUpdate = (
    username: string,
    action: (payload: { username: string, resolve: () => void, reject: () => void, groupId: ?string }) => Promise<*>,
    groupId: ?string,
    id: ?string = null,
  ) =>
    new Promise((resolve, reject) => action({ username, id, resolve, reject, groupId })).then(() => {
      this.onChangedApprovals();
    });

  onSelectSuggestion = (suggestion: ChatUserRecord) => {
    const { createApproval } = this.props;
    this.onTagsUpdate(suggestion.tag, createApproval, suggestion.group_id);
  };

  onRemove = (tag: UserEnhancedApprovalRecord) => {
    const { removeApproval } = this.props;
    const groupId = '';
    // we want to pass id for approvals that has it or tag for those that dont
    this.onTagsUpdate(tag.tag, removeApproval, groupId, tag.id);
  };

  //

  onSortOver = ({ newIndex }) => {
    const overNode = this.tags.get(newIndex);

    this.setState({ overNode });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const [oldItem, newItem] = [oldIndex, newIndex].map((i) => this.tags.get(i));
    const { changeOrderApproval, refreshDoc, documentId, companyId } = this.props;

    if (oldItem && newItem && this.getIsDropEnabled(newItem) && oldIndex !== newIndex) {
      new Promise((resolve, reject) =>
        changeOrderApproval({
          username: oldItem.tag,
          position: newIndex,
          resolve,
          reject,
          oldIndex,
          newIndex,
        }),
      ).then(() => {
        refreshDoc({ documentId, companyId, isLoader: false });
      });
    }
    this.setState({ overNode: null });
  };

  getIsSortableItemDisabled = (item) => {
    const { isFlowActive } = this.props;
    return isFlowActive || !['draft', ''].some((s) => item.status === s);
  };

  getIsDropEnabled(node) {
    return node ? !this.getIsSortableItemDisabled(node) : true;
  }

  // Logic from this getter have to duplicate in memoizeTags
  get tags(): List<UserEnhancedApprovalRecord> {
    const { tags } = this.props;
    return filterTags(tags);
  }

  get value() {
    const { approvers } = this.props;

    return approvers.filter((a) => this.tags.some((t) => t.tag === a.userGuid));
  }

  // eslint-disable-next-line react/sort-comp
  memoizeFilterTags = memoizeOne(filterTags);

  get memoizeTags(): List<UserEnhancedApprovalRecord> {
    const { tags } = this.props;
    return this.memoizeFilterTags(tags);
  }

  get wrapperWidth(): Number {
    const { general } = this.props;
    if (this.wrapper) {
      return general ? 800 : this.wrapper.getBoundingClientRect().width;
    }
    return 0;
  }

  get previewTags(): Array<UserEnhancedApprovalRecord> {
    const previewTags = [];

    if (this.wrapper) {
      /*
        Tags component render inside DocumentPdf, and we does not have correct ref to wrapper,
        for general it is always 800, temporary solution
      */
      let tw = 170;
      const checkedTags = this.tags;
      checkedTags.forEach((tag) => {
        const string = tag.fullName ? tag.fullName : tag.tag;
        tw += string.length * 6 + 70;
        if (tw < this.wrapperWidth) {
          previewTags.push(tag);
        }
      });
    }

    return previewTags;
  }

  get tailSize(): number {
    return [...this.tags].splice(this.previewTags.length).length;
  }

  get tagsReadAllowed(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_AUTHORIZED);
  }

  get tagsEditingAllowed(): boolean {
    // isEnabledApprovals - approvals is enabled in feature set
    const { isGranted, isEnabledApprovals, isEditableApprovals } = this.props;
    return (isGranted(ACL.IS_AUTHORIZED) && isEnabledApprovals) || isEditableApprovals;
  }

  setWrapperRef = (ref: ?HTMLElement) => {
    this.wrapper = ref;
    this.forceUpdate();
  };

  getPicture = (tag: ChatUserRecord) => {
    const { organizationUsers } = this.props;
    const user = getSystemUser(tag.tag, organizationUsers);
    return user ? user.picture : null;
  };

  submitApproval = () => {
    const {
      submitApproval,
      isFlowActive,
      approvalGroups,
      getApprovalGroups,
      confirm,
      documentId,
      companyId,
      refreshDoc,
    } = this.props;

    const descriptionProps = !isFlowActive
      ? {
          id: 'document.approval.activation_message',
          defaultMessage: 'The Approval Flow for this document will be activated.',
        }
      : {
          id: 'document.approval.deactivation_message',
          defaultMessage: 'The Approval Flow for this document will be deactivated.',
        };

    const confirmationTextProps = !isFlowActive
      ? {
          id: 'document.approval.activation_button',
          defaultMessage: 'Activate',
        }
      : {
          id: 'document.approval.deactivation_button',
          defaultMessage: 'Dectivate',
        };

    confirm({
      title: <FormattedMessage id="document.approval.activation_title" defaultMessage="Approval Flow Activation" />,
      description: (
        <>
          <FormattedMessage {...descriptionProps} />{' '}
          <FormattedMessage id="document.approval.activation_confirm" defaultMessage="Are you sure?" />
        </>
      ),
      confirmationText: <FormattedMessage {...confirmationTextProps} />,
      confirmationButtonProps: { color: isFlowActive ? 'error' : 'primary' },
    })
      .then(() => {
        const action = new Promise((resolve, reject) => {
          const status = isFlowActive ? 'draft' : 'active';
          submitApproval({ status, resolve, reject });
        });

        action
          .then(() => {
            if (!isFlowActive) {
              this.onToggle();
            }
            if (approvalGroups.size === 0) {
              getApprovalGroups();
            }
            refreshDoc({ documentId, companyId, isLoader: false });
          })
          .catch();
      })
      .catch(()=>{});
  };

  renderLockedModal = () => {
    const {
      confirm,
      theme: { labelOptions },
    } = this.props;
    confirm({
      title: <FormattedMessage id="document.approval.lockedModal.title" defaultMessage="Approvals flow is disabled" />,
      content: (
        <LockedModalContent>
          <FormattedMessage
            id="document.approval.lockedModal.headerText"
            defaultMessage="The Approval Flow is used when a document requires approval
            before submission to the accounting system."
          />
          <LockedModalList>
            <li>
              <FormattedMessage
                id="document.approval.lockedModal.option1"
                defaultMessage="Add the people that need to approve the document"
              />
            </li>
            <li>
              <FormattedMessage
                id="document.approval.lockedModal.option2"
                defaultMessage="Activate the Approval Flow"
              />
            </li>
            <li>
              <FormattedMessage
                id="document.approval.lockedModal.option3"
                defaultMessage="An email request will be sent to each person in the defined order"
              />
            </li>
          </LockedModalList>
          <FormattedMessage
            id="document.approval.lockedModal.footerText"
            defaultMessage="Contact our sales team in order to unlock Approval flow."
          />
        </LockedModalContent>
      ),
      confirmationText: (
        <FormattedMessage id="document.approval.lockedModal.confirmButton" defaultMessage="Contact Us" />
      ),
    })
      .then(() => {
        window.location = `mailto:${labelOptions.supportEmail}`;
      })
      .catch(() => {});
  };

  render() {
    const {
      classes,
      isFlowActive,
      isEnabledApprovals,
      isEditableApprovals,
      intl: { formatMessage },
      isScheduledApproval,
      activeGroupName,
      approvers,
      levels,
      activeAdvancedGroupName,
      activeAdvancedGroupId,
    } = this.props;

    const { open } = this.state;
    const editable = (
      <Autocomplete
        fullWidth
        margin="normal"
        noOptionsText={
          this.value.length + 1 <= this.approvalsLimit ? (
            <FormattedMessage
              id="document.approval.noSuggestions"
              defaultMessage="No more users available for selection"
            />
          ) : (
            <FormattedMessage
              id="document.approval.limitReached"
              defaultMessage={`${this.value.length} users limit exceeded`}
              values={{ limit: this.value.length }}
            />
          )
        }
        options={this.value.length + 1 <= this.approvalsLimit ? approvers : []}
        getOptionLabel={(o) => o.group_id || o.id}
        groupBy={(option) => option.group}
        value={this.value}
        onChange={this.onChange}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="filled"
            size="small"
            data-element={elements.content.documents.approvals.input}
            label={formatMessage({
              id: 'configurations.company.approvals.groups.select.placeholder',
              defaultMessage: 'Select approvers...',
            })}
          />
        )}
        getOptionDisabled={(o) =>
          !!(
            (o.group_id && o.approvers.every((a) => this.value.some((v) => v.id === a.id))) ||
            o.id === activeAdvancedGroupId
          )
        }
        renderOption={(params, option, { selected, disabled }) => {
          if (option.group_id) {
            return (
              <MenuItem
                {...params}
                {...{ selected, disabled }}
                sx={{ gap: 2 }}
                data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.container}
              >
                <ApprovalsAvatarGroup
                  data-element={
                    elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.listOfApprovals
                  }
                  approvers={option.approvers}
                />
                <ListItemText
                  data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.name}
                >
                  {option.group_title}
                </ListItemText>
              </MenuItem>
            );
          }

          if (option.levels) {
            return (
              <MenuItem
                {...params}
                {...{ selected, disabled }}
                sx={{ gap: 2 }}
                data-element={
                  elements.content.documents.approvals.autocompleteOptions.advancedApprovalGroupItem.container
                }
              >
                <ApproverLevels value={option.levels} collapseAll withoutBorderForLevelContainer />
                <ListItemText
                  data-element={elements.content.documents.approvals.autocompleteOptions.advancedApprovalGroupItem.name}
                >
                  {option.name}
                </ListItemText>
              </MenuItem>
            );
          }

          return (
            <MenuItem
              {...params}
              {...{ selected, disabled }}
              data-element={elements.content.documents.approvals.autocompleteOptions.approvalItem}
            >
              <ListItemText>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Avatar src={option.picture} alt={option.fullName} sx={{ width: 24, height: 24 }} />
                  {option.fullName}
                  <br />({option.tag})
                </Stack>
              </ListItemText>
            </MenuItem>
          );
        }}
        renderTags={() => null}
        renderGroup={(params) => (
          <li
            key={params.group}
            data-element={`${elements.content.documents.approvals.autocompleteOptions.groupsContainer}.${params.group}`}
          >
            <ListSubheader component="div" sx={{ top: (theme) => theme.spacing(-1), zIndex: 10 }}>
              <Typography variant="overline" color="primary">
                {formatMessage(messages[params.group], { count: params.children.length })}
              </Typography>
            </ListSubheader>
            <MenuList>{params.children}</MenuList>
          </li>
        )}
      />
    );

    const readable = (
      <>
        <Stack flex={1} ref={this.setWrapperRef}>
          {this.tags.size === 0 && levels.length === 0 && (
            <Typography variant="body2" color={!this.tagsEditingAllowed ? 'grey.500' : undefined}>
              <FormattedMessage id="document.approval.emptyText" defaultMessage="Click to set approvals flow" />
            </Typography>
          )}
          <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center" overflow="inherit">
            {this.previewTags.map((tag, index) => (
              <Approver
                key={tag.id}
                disabled={isScheduledApproval}
                label={tag.fullName}
                onClick={() => this.onSelectSuggestion(tag)}
                avatar={<Avatar alt={tag.fullName} src={this.getPicture(tag)} />}
                status={tag.status}
                hasArrow={!!this.tailSize || index + 1 !== this.previewTags.length}
              />
            ))}
            {!!levels.length && (
              <ApproverLevels value={levels} withStatus hideExtra maxWidth={this.wrapperWidth} considerChipWidth />
            )}
            {!this.previewTags.length && !!this.tags.size && (
              <Typography>
                <FormattedMessage id="document.approval.panel_title" defaultMessage="Approvals:" />
              </Typography>
            )}
            {!!this.tailSize && <Chip label={`+${this.tailSize}`} />}
          </Stack>
        </Stack>

        {this.tagsReadAllowed && isEnabledApprovals && (
          <ArrowButton
            data-element={elements.content.documents.approvals.expand}
            color="primary"
            disabled={(!this.tagsEditingAllowed && this.tags.size === 0 && levels.length === 0) || isScheduledApproval}
            onClick={this.onToggle}
            sx={{ ml: 1 }}
          />
        )}
        {!isEnabledApprovals && <LockIcon color="primary" />}
      </>
    );

    const endEdge = (
      <Stack direction="row" alignItems="center" justifyContent="end">
        {isEditableApprovals && (
          <TooltipForButton
            t={
              isFlowActive
                ? {
                    id: 'switcher.on',
                    defaultMessage: 'ON',
                  }
                : {
                    id: 'switcher.off',
                    defaultMessage: 'OFF',
                  }
            }
          >
            <Switch
              data-element={elements.content.documents.approvals.toggler}
              onClick={this.submitApproval}
              checked={isFlowActive}
              disabled={(this.tags.size === 0 && levels.length === 0) || !this.tagsEditingAllowed}
            />
          </TooltipForButton>
        )}
        <ArrowButton
          data-element={elements.content.documents.approvals.expand}
          open
          color="primary"
          disabled={isScheduledApproval}
          onClick={this.onToggle}
          sx={{ ml: 1 }}
        />
      </Stack>
    );

    return (
      <Stack
        direction="row"
        role="button"
        data-element={elements.content.documents.approvals.container}
        flex={1}
        alignItems="center"
        sx={{
          position: 'relative',
          padding: (theme) => theme.spacing(isEnabledApprovals ? 1 : 0, 3),
          border: 1,
          borderColor: (theme) => theme.palette.grey[300],
          bgcolor: (theme) => (open ? theme.palette.common.white : 'inherit'),
          boxShadow: (theme) => (open ? theme.shadows[3] : 'none'),
          borderLeft: 'none',
          borderRight: 'none',
          cursor:
            (!this.tagsEditingAllowed && this.tags.size === 0 && levels.length === 0) || isScheduledApproval
              ? 'not-allowed'
              : 'pointer',
          ...(!isEnabledApprovals && { height: '100%' }),
        }}
        spacing={1}
        onClick={() => {
          if (!isEnabledApprovals) {
            this.renderLockedModal();
            return;
          }
          if (!open && (this.tags.size > 0 || this.tagsEditingAllowed)) {
            this.onToggle();
          }
        }}
      >
        <Stack direction="row" gap={1} flex={1} alignItems="center" flexWrap="wrap" maxWidth="100%">
          {!isFlowActive && open && this.tagsEditingAllowed && (
            <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
              {editable}
              <EndEdgeWrapper direction="row" justifyContent="flex-end">
                {endEdge}
              </EndEdgeWrapper>
            </Stack>
          )}
          {open ? (
            <Stack direction="row" flex={1} justifyContent="space-between" maxWidth="100%" minWidth={0}>
              <Stack direction="row" flexWrap="nowrap" flexGrow={1} alignItems="center" maxWidth="100%" minWidth={0}>
                {(activeGroupName || activeAdvancedGroupName) && (
                  <>
                    <Typography
                      varian="body1"
                      sx={{ maxWidth: 'max(300px, 25%)', wordBreak: 'break-word', minWidth: 80 }}
                      data-element={elements.content.documents.approvals.selectedGroupName}
                    >
                      {activeGroupName || activeAdvancedGroupName}
                    </Typography>
                    <Divider orientation="vertical" sx={{ mx: 2 }} flexItem />
                  </>
                )}
                {!!this.memoizeTags.size && (
                  <SortableList
                    items={this.memoizeTags}
                    getIsItemDisabled={this.getIsSortableItemDisabled}
                    onSortEnd={this.onSortEnd}
                    onSortOver={this.onSortOver}
                    idKey="tag"
                    data-element={elements.content.documents.approvals.listOfApprovals}
                    helperClass={cx(classes.approvalDraggableElem, {
                      [classes.approvalDraggableElemDisabledDrop]: !this.getIsDropEnabled(this.state.overNode),
                    })}
                    Element={(props) => (
                      <Stack direction="row" flexWrap="wrap" alignItems="center" gap={1}>
                        {props.children}
                      </Stack>
                    )}
                  >
                    {(tag, index) => (
                      <Approver
                        key={tag.id}
                        className={tag.className}
                        disabled={isScheduledApproval}
                        label={tag.fullName}
                        onDelete={
                          !isFlowActive && this.tagsEditingAllowed && tag.status !== 'approved'
                            ? () => this.onRemove(tag)
                            : undefined
                        }
                        avatar={<Avatar alt={tag.fullName} src={this.getPicture(tag)} />}
                        status={tag.status}
                        hasArrow={!tag.dragging && index + 1 !== this.memoizeTags.size}
                      />
                    )}
                  </SortableList>
                )}
                {!!levels.length && <ApproverLevels value={levels} withStatus expandAll />}
              </Stack>
              {!!levels.length && (
                <Stack justifyContent="center" mx={1}>
                  <IconButton
                    color="error"
                    onClick={this.onRemoveAdvancedGroup}
                    disabled={isScheduledApproval || isFlowActive}
                    data-element={elements.content.documents.approvals.deleteAdvancedGroup}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Stack>
              )}
              <EndEdgeWrapper direction="row" justifyContent="flex-end">
                {isFlowActive && endEdge}
              </EndEdgeWrapper>
            </Stack>
          ) : null}
          {!open && <>{readable}</>}
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationUsers: organizationUsersForConfidentialDocSelector(state),
  isGranted: ACL.isGranted(state),
  tags: userEnhancedApprovalsListSelector(state),
  isFlowActive: approvalsStatusAsBoolSelector(state),
  isEnabledApprovals: companyApprovalsFeatureEnabledSelector(state),
  isEditableApprovals: approvalsEditableSelector(state),
  users: chatAllUsersSelector(state),
  isScheduledApproval: isDocumentScheduledApprovalSelector(state),
  approvalGroups: approvalGroupListSelector(state),
  activeGroupName: activeGroupNameEnhancedSelector(state),
  activeAdvancedGroupName: activeAdvancedGroupNameEnhancedSelector(state),
  levels: userEnhancedApprovalsLevelsListSelector(state),
  activeAdvancedGroupId: approvalActiveAdvancedGroupIdSelector(state),
});

const mapDispatchToProps = {
  createApproval: createApprovalAction,
  removeApproval: removeApprovalAction,
  createAdvancedApprovalGroup: addAdvancedApprovalGroupAction,
  removeAdvancedApprovalGroup: removeAdvancedApprovalGroupAction,
  changeOrderApproval: changeOrderApprovalAction,
  submitApproval: submitApprovalAction,
  refreshDoc: documentGet,
  toggleAFPanel: toggleAFPanelAction,
  getApprovalGroups: getApprovalGroupsAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApprovals,
  withConfirm,
  injectIntl,
  withStyles(sheet, { withTheme: true }),
)(Approval);
