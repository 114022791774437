/* @flow */
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Outlet, generatePath, useNavigate } from 'react-router-dom';

import {
  configurationCompanySelector,
  companyFeatureSetSelector,
  companyFeatureSetLoadedSelector,
} from 'domain/companies/companiesSelector';
import { currentConnectedERPselector, isLoadingERPSettingSelector } from 'domain/settings/settingsSelector';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import Tabs from 'components/Tabs';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import FeatureDisabledMessage from 'pages/configurations/components/FeatureDisabledMessage';

import { APPROVAL_TABS } from './helpers';

const mapStateToProps = (state) => ({
  featureSet: companyFeatureSetSelector(state),
  isFeatureSetLoaded: companyFeatureSetLoadedSelector(state),
  currentConnectedERP: currentConnectedERPselector(state),
  isLoadingERPSetting: isLoadingERPSettingSelector(state),
});

const ApprovalsPage = () => {
  const notSupportedErps = [
    'QBO',
    'QBD',
    'SAGE_SA',
    'SAGE',
    'ZOHO',
    'ACUMATICA',
    'MICROSOFT_DYNAMICS_BC',
    'MICROSOFT_DYNAMICS_NAV',
    'XERO',
  ];
  const navigate = useNavigate();
  const company = useSelector(configurationCompanySelector);
  const { '*': asterisk, companyId } = useParams();
  const [, section] = asterisk.split('/');
  const { featureSet, isFeatureSetLoaded, currentConnectedERP, isLoadingERPSetting } = useSelector(mapStateToProps);
  const vendorBasedApprovalsHidden = useMemo(() => notSupportedErps.includes(currentConnectedERP), [currentConnectedERP]);

  const tabs = useMemo(
    () => [
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_APPROVERS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.approvers',
        defaultMessage: 'Approvers list',
        tabValue: APPROVAL_TABS.APPROVERS,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_GROUPS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.groups',
        defaultMessage: 'Basic approval groups',
        tabValue: APPROVAL_TABS.GROUPS,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_ADVANCED_GROUPS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.advancedGroups',
        defaultMessage: 'Advanced approval groups',
        tabValue: APPROVAL_TABS.ADVANCED_GROUPS,
        disabled: !featureSet.get('approval_advanced_groups', false),
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_VENDOR_BASED_APPROVAL.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.vendorBasedApproval',
        defaultMessage: 'Vendor-based approvals',
        tabValue: APPROVAL_TABS.VENDOR_BASED_APPROVAL,
        hidden: vendorBasedApprovalsHidden,
      },
    ],
    [companyId, featureSet, vendorBasedApprovalsHidden],
  );

  useEffect(() => {
    if (
      (!section && featureSet.get('approvals', false)) ||
      (section && section === APPROVAL_TABS.ADVANCED_GROUPS && !featureSet.get('approval_advanced_groups', false))
    ) {
      navigate(generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_APPROVERS.absolute, { companyId }));
    }
  }, [section, companyId, navigate, featureSet]);

  if (!isFeatureSetLoaded || isLoadingERPSetting) {
    return <LinearProgress />;
  }

  return featureSet.get('approvals', false) ? (
    // flex={1} added for stretch content for ag-grid height=100% style
    <Stack alignItems="flex-start" direction="row" flex={1}>
      <Paper variant="outlined" sx={{ width: 270, border: 2, mr: 5, p: 1, pr: 0, borderColor: 'grey.200' }}>
        <Tabs value={section} tabs={tabs} orientation="vertical" textAlign="flex-end" />
      </Paper>
      <Stack component={Paper} flex={1} height="100%" sx={{ p: 3 }}>
        {company.id ? <Outlet /> : null}
      </Stack>
    </Stack>
  ) : (
    <FeatureDisabledMessage />
  );
};

export default ApprovalsPage;
