// @flow
import React, { useEffect, useState, useRef, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Approver from 'pages/components/Approver';
import Chip from '@mui/material/Chip';
import ApprovalsAvatarGroup from 'pages/components/ApprovalsAvatarGroup';

import type { ApprovalLevelsWithUserData } from 'domain/approvals/types';

type TApproverLevels = {
  value: ApprovalLevelsWithUserData,
  withStatus?: boolean,
  expandAll?: boolean,
  hideExtra?: boolean,
  collapseAll?: boolean,
  considerChipWidth?: boolean,
  maxWidth?: number | string,
  direction?: 'row' | 'column',
  withoutBorderForLevelContainer?: boolean,
};

const getLabel = ({ isSingleApprover, withStatus, expandAll, approvers, status, flowType, collapseAll, direction }) => {
  if (expandAll || (withStatus && (status === 'pending' || isSingleApprover) && !collapseAll)) {
    return (
      <Stack direction={direction} spacing={1} flexWrap="nowrap" alignItems="center" overflow="inherit">
        {approvers.map((a, j) => (
          <Approver
            key={j}
            arrowDirection={direction}
            avatar={<Avatar alt={a.fullName} src={a.picture} />}
            status={withStatus ? a.status : 'draft'}
            label={a.fullName}
            variant="outlined"
            hasArrow={j + 1 !== approvers.length}
            arrowIconType={flowType}
            size="small"
          />
        ))}
      </Stack>
    );
  }

  if (isSingleApprover && !collapseAll) {
    return approvers[0].fullName;
  }

  return <ApprovalsAvatarGroup approvers={approvers} sx={{ mx: -1 }} />;
};

const ApproverLevels: React$StatelessFunctionalComponent<TApproverLevels> = ({
  value,
  withStatus = false,
  expandAll = false,
  hideExtra = false,
  collapseAll = false,
  maxWidth = '100%',
  considerChipWidth = false,
  direction = 'row',
  withoutBorderForLevelContainer = false,
}) => {
  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(value.length);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [collapseChips, setCollapseChips] = useState(collapseAll);
  const levelElementsRef = useRef([]);
  const levelsTotalWidthCollapsed = useRef(0);
  const levelsTotalWidthExpanded = useRef(0);

  const setElementRef = useCallback((ref) => {
    if (!ref) return;

    if (!levelElementsRef.current.includes(ref)) {
      levelElementsRef.current.push(ref);
    }
  }, []);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (containerRef.current && hideExtra) {
        const containerWidth = containerRef.current.parentElement.clientWidth;
        let totalWidth = 0;

        let count = 0;
        Array.from(containerRef.current.children).forEach((child, index) => {
          totalWidth += child.offsetWidth + 4; // gap = 4px
          // 39px - chip width
          const spaceForChip = considerChipWidth && index !== value.length - 1 ? 39 : 0;
          if (totalWidth + spaceForChip < containerWidth) {
            count = index + 1;
          }
        });

        setVisibleCount(count);
        setHiddenCount(value.length - count);
      } else {
        setVisibleCount(value.length);
      }
    };

    updateVisibleCount();
    // maxWidth is added to the dependency array for the component renderer
    // when it is in the middle of an autocomplete in a grid and the user changes the column width
  }, [value, hideExtra, considerChipWidth, maxWidth]);

  // document page, approval flow activated - collapse active level when not fit in container(fast solution :( )
  useEffect(() => {
    if (hideExtra && !collapseAll) {
      let totalWidth = 0;

      levelElementsRef.current.forEach((levelElement) => {
        const { width } = levelElement.getBoundingClientRect();
        totalWidth += width + 4;
      });

      if (collapseChips) {
        levelsTotalWidthCollapsed.current = totalWidth;
      } else {
        levelsTotalWidthExpanded.current = totalWidth;
      }

      if (totalWidth > maxWidth) {
        setCollapseChips(true);
      }

      if (levelsTotalWidthExpanded.current < maxWidth) {
        setCollapseChips(false);
      }
    }
  }, [maxWidth, collapseAll, collapseChips, hideExtra]);

  return (
    <Stack
      ref={containerRef}
      direction={direction}
      gap={0.5}
      flexWrap={hideExtra ? 'nowrap' : 'wrap'}
      maxWidth={maxWidth}
      minWidth={0}
    >
      {value.map(({ approvers, status = 'draft', flowType }, i) => {
        const isSingleApprover = approvers.length === 1;
        const avatar =
          isSingleApprover && !withStatus && !collapseChips && !expandAll ? (
            <Avatar alt={approvers[0].fullName} src={approvers[0].picture} />
          ) : null;

        const label = getLabel({
          isSingleApprover,
          withStatus,
          expandAll,
          approvers,
          status,
          flowType,
          collapseAll: collapseChips,
          direction,
        });

        return (
          <Stack key={i} direction={direction} flexWrap="nowrap" gap={0.5} minWidth={0} flexShrink={0} maxWidth="100%">
            {visibleCount === 0 && i === 0 && <Chip label={`+${hiddenCount}`} />}
            <Approver
              avatar={avatar}
              status={withStatus ? status : 'draft'}
              sx={{ opacity: visibleCount > i ? 1 : 0 }}
              label={label}
              variant="outlined"
              arrowDirection={direction}
              hasArrow={i + 1 !== value.length}
              isLevel
              withoutBorderForLevelContainer={withoutBorderForLevelContainer}
              ref={setElementRef}
            />
            {visibleCount !== value.length && visibleCount !== 0 && i === visibleCount - 1 && (
              <Chip label={`+${hiddenCount}`} />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ApproverLevels;
