// @flow
// $FlowFixMe outdated react types
import React, { useCallback } from 'react';
// $FlowFixMe outdated react types
import { useIntl, FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemTextMui from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';

import { filterOptions, messages } from 'hooks/approval/useApprovals';
import elements from 'components/elements';

import Autocomplete from 'pages/components/ApproversAutocomplete';
import Approver from 'pages/components/Approver';
import ApproverLevels from 'pages/components/ApproverLevels';
import Typography from '@mui/material/Typography';
import SortableList from 'pages/document/DocumentTopPanel/approval/dnd/SortableList';
import { arrayMove } from '@dnd-kit/sortable';
import ApprovalsAvatarGroup from 'pages/components/ApprovalsAvatarGroup';

const MenuList = styled('ul')(() => ({ padding: 0 }));
const ListItemText = styled(ListItemTextMui)(() => ({ whiteSpace: 'normal', wordWrap: 'break-word' }));

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyTags: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: '1 1 100%',
    },
    approvalDraggableElem: {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

type Props = {|
  approvers: Array<*>,
  currentValue: Array<*>,
  setValue: (value: any) => Array<*>,
  setGroupId: (groupId: string) => void,
  setAdvancedGroupId: (advancedGroupId: string) => void,
  groupId: string,
  advancedGroupId: string,
|};

const StandalontApprovalManager: React$StatelessFunctionalComponent<Props> = ({
  approvers,
  setValue,
  currentValue,
  setGroupId,
  groupId,
  advancedGroupId,
  setAdvancedGroupId,
}) => {
  const { formatMessage } = useIntl();
  // @to-do move to useApprovals hook
  const approvalsLimit = 8;
  const classes = useStyles();

  const onChange = useCallback(
    (_, selected, reason, selectedOption) => {
      if (reason === 'clear') {
        setGroupId('');
        setAdvancedGroupId('');
        setValue([]);
      } else {
        const isAdvancedGroup = !!selectedOption.option?.levels;
        const basicGroupId = selectedOption.option?.group_id;

        const newValues = isAdvancedGroup
          ? selectedOption?.option.levels
          : selected.reduce((acc, option) => {
              if (option.flowType) {
                return acc;
              }
              if (option?.approvers) {
                option.approvers.forEach((approver) => {
                  if (!acc.some((item) => item.id === approver.id)) {
                    acc.push(approver);
                  }
                });
              } else {
                acc.push(option);
              }
              return acc;
            }, []);

        const isBasicGroup = (advancedGroupId || selected.length === 1) && basicGroupId;

        if (newValues.length > approvalsLimit) {
          newValues.length = approvalsLimit;
        }

        setGroupId(isBasicGroup ? basicGroupId : '');
        setAdvancedGroupId(isAdvancedGroup ? selectedOption.option.id : '');
        setValue(newValues);
      }
    },
    [setValue, setGroupId, setAdvancedGroupId, advancedGroupId],
  );

  const handleDelete = (id) => {
    if (groupId) {
      setGroupId('');
    }
    setValue((current) => current.filter((value) => value.id !== id));
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setValue((list) => arrayMove(list, oldIndex, newIndex));
  }, []);

  return (
    <Autocomplete
      fullWidth
      margin="normal"
      disableClearable={false}
      noOptionsText={
        currentValue.length + 1 <= approvalsLimit ? (
          <FormattedMessage
            id="document.approval.noSuggestions"
            defaultMessage="No more users available for selection"
          />
        ) : (
          <FormattedMessage
            id="document.approval.limitReached"
            defaultMessage={`${approvalsLimit} users limit exceeded`}
            values={{ limit: approvalsLimit }}
          />
        )
      }
      options={currentValue.length + 1 <= approvalsLimit ? approvers : []}
      getOptionLabel={(o) => o.group_id || o.id || o.minAmount}
      groupBy={(option) => option.group}
      value={currentValue}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="filled"
          size="small"
          data-element={elements.content.documents.approvals.input}
          label={formatMessage({
            id: 'configurations.company.approvals.groups.select.placeholder',
            defaultMessage: 'Select approvers...',
          })}
        />
      )}
      getOptionDisabled={(o) =>
        !!(
          (o.group_id &&
            (o.approvers.every(({ id }) => currentValue.some((v) => v?.id === id)) || o.group_id === groupId)) ||
          (o.id && o.id === advancedGroupId)
        )
      }
      renderOption={(params, option, { selected, disabled }) => {
        if (option.group_id) {
          return (
            <MenuItem
              {...params}
              selected={selected}
              disabled={disabled}
              sx={{ gap: 2 }}
              data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.container}
            >
              <ApprovalsAvatarGroup approvers={option.approvers} />
              <ListItemText
                data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.name}
              >
                {option.group_title}
              </ListItemText>
            </MenuItem>
          );
        }

        if (option.levels) {
          return (
            <MenuItem
              {...params}
              selected={selected}
              disabled={disabled}
              sx={{ gap: 2 }}
              data-element={
                elements.content.documents.approvals.autocompleteOptions.advancedApprovalGroupItem.container
              }
            >
              <ApproverLevels value={option.levels} collapseAll withoutBorderForLevelContainer />
              <ListItemText
                data-element={elements.content.documents.approvals.autocompleteOptions.advancedApprovalGroupItem.name}
              >
                {option.name}
              </ListItemText>
            </MenuItem>
          );
        }

        return (
          <MenuItem
            {...params}
            selected={selected}
            disabled={disabled}
            data-element={elements.content.documents.approvals.autocompleteOptions.approvalItem}
          >
            <ListItemText>
              <Stack alignItems="center" direction="row" gap={1}>
                <Avatar src={option.picture} alt={option.fullName} sx={{ width: 24, height: 24 }} />
                {option.fullName}
                <br />({option.tag})
              </Stack>
            </ListItemText>
          </MenuItem>
        );
      }}
      renderTags={(selected) =>
        advancedGroupId ? (
          <ApproverLevels value={currentValue} collapseAll />
        ) : (
          <SortableList
            items={selected}
            className={classes.bodyTags}
            idKey="id"
            onSortOver={() => {}}
            onSortEnd={onSortEnd}
            Element={(props) => (
              <Stack
                gap={1}
                flexWrap="wrap"
                direction="row"
                mt={1}
                {...props}
                data-element={elements.popup.approvalGroups.selectedApproversContainer}
              >
                {props.children}
              </Stack>
            )}
            helperClass={classes.approvalDraggableElem}
          >
            {({ fullName, picture, id, className, style, idx, dragging }) => (
              <Approver
                avatar={<Avatar alt={fullName} src={picture} />}
                label={fullName}
                variant="outlined"
                onDelete={() => {
                  handleDelete(id);
                }}
                className={className}
                style={style}
                hasArrow={idx + 1 !== selected.length && !dragging}
              />
            )}
          </SortableList>
        )
      }
      renderGroup={(params) => (
        <li
          key={params.group}
          data-element={`${elements.content.documents.approvals.autocompleteOptions.groupsContainer}.${params.group}`}
        >
          <ListSubheader component="div" sx={{ top: (theme) => theme.spacing(-1), zIndex: 10 }}>
            <Typography variant="overline" color="primary">
              {formatMessage(messages[params.group], { count: params.children.length })}
            </Typography>
          </ListSubheader>
          <MenuList>{params.children}</MenuList>
        </li>
      )}
    />
  );
};

export default StandalontApprovalManager;
