// @flow
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { nanoid, customAlphabet } from 'nanoid';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

// selectors
import { isPendingUploadSelector, uploadQueueSelector } from 'domain/documents/documentSelector';
import { userIdSelector } from 'domain/env/envSelector';
import { companiesByIdSelector } from 'domain/companies/companiesSelector';
import { chatAccountantSelector } from 'domain/chat/chatSelector';

// actions
import { supplierDocumentUploadAction, queueRemoveAction, queueAddAction } from 'domain/documents/documentsActions';

// components
import Dropzone from 'pages/common/Dialog/DialogUploadDocuments/dropzone';
import Queue from 'pages/common/Dialog/DialogUploadDocuments/queue';
import Autocomplete from './components/Autocomplete';
import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from 'components/mui/Avatar';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadText, FileInput, SubjectInput, MessageInput } from './StyledComponents';

// intl
import { FormattedMessage, useIntl } from 'react-intl';

// helpers
import { checkFiles, checkFileSize } from 'pages/common/Dialog/DialogUploadDocuments/helpers';
import { paramsAdapter, uploadQueueItemAdapter } from 'domain/documents/adapters';
import acceptTypes from 'lib/acceptTypes';

import { lightBlue } from '@mui/material/colors';

type Props = {
  companyId: string,
  close: () => void,
  open: boolean,
};

const customNanoid = customAlphabet('1234567890abcdef', 10);

const mapStateToProps = (state) => ({
  companies: companiesByIdSelector(state),
  email: userIdSelector(state),
  accountants: chatAccountantSelector(state),
  queue: uploadQueueSelector(state),
  isPendingUpload: isPendingUploadSelector(state),
});

const Upload: React$StatelessFunctionalComponent<Props> = ({ companyId, close, open }) => {
  const dispatch = useDispatch();
  const linkID = customNanoid();
  const { formatMessage } = useIntl();

  const fileInputElRef = useRef(null);
  const [isFileDialogActive, setIsFileDialogActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [text, setText] = useState('');
  const [subjectText, setSubjectText] = useState('');
  const [bkList, setBKList] = useState([]);

  const { companies, email, accountants, queue, isPendingUpload } = useSelector(mapStateToProps);

  const remove = useCallback((props) => dispatch(queueRemoveAction(props)), [dispatch]);

  const onChangeText = (e: SyntheticEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };
  const onSubjectText = (e: SyntheticEvent<HTMLInputElement>) => {
    setSubjectText(e.target.value);
  };

  const onSelectChange = useCallback((event, list) => {
    setBKList(list);
  }, []);

  const { companyName, companyLogo } = useMemo(() => {
    const company = companies.get(companyId);
    return { companyName: company.get('cname'), companyLogo: company.get('logo') };
  }, [companyId, companies]);

  const check = useCallback((fileType: string) => checkFiles(acceptTypes, fileType), []);

  const createQueue = useCallback(
    (file: File, path) => {
      const add = (args) => dispatch(queueAddAction({ ...args }));
      const id: string = nanoid(10);
      const { name, type, size } = file;
      const splittedName = name.split('.');
      const fileType = type.length > 0 ? type : `.${splittedName[splittedName.length - 1]}`;
      const isValidType = check(fileType);
      const isValidSize = checkFileSize(size);
      const isValidFile = isValidType && isValidSize;

      if (isValidFile) {
        dispatch(
          queueAddAction(
            uploadQueueItemAdapter({
              file,
              id,
              path,
              isConfidential: false,
              params: { day: new Date() },
            }),
          ),
        );
      } else if (!isValidType) {
        compose(add, uploadQueueItemAdapter)({ path, status: 'unsupportedType', id });
      } else if (!isValidSize) {
        compose(add, uploadQueueItemAdapter)({ path, status: 'maxFileSizeError', id });
      }
    },
    [dispatch, check],
  );

  const transferDndFilter = useCallback(
    (item, p?: string) => {
      const path = p || '';
      if (item) {
        createQueue(item, `${path}${item.name}`);
      }
    },
    [createQueue],
  );

  const onDndDrop = useCallback(
    (files) => {
      if (!isFileDialogActive && files) {
        for (let i = 0; i < files.length; i++) {
          transferDndFilter(files[i]);
        }
      }
    },
    [isFileDialogActive, transferDndFilter],
  );

  const transferFilter = useCallback(
    (item, p?: string) => {
      const path = p || '';
      if (item && item.isFile) {
        item.file((file) => createQueue(file, `${path}${file.name}`));
      } else if (item && item.isDirectory) {
        const dirReader = item.createReader();
        dirReader.readEntries((entries) => {
          for (let i = 0; i < entries.length; i++) {
            transferFilter(entries[i], `${path}${item.name}/`);
          }
        });
      }
    },
    [createQueue],
  );

  const onDrop = useCallback(
    (e: SyntheticDragEvent<any>) => {
      if (!isFileDialogActive && e.dataTransfer && 'items' in e.dataTransfer) {
        const { items } = e.dataTransfer;
        for (let i = 0; i < items.length; i++) {
          if (typeof items[i].webkitGetAsEntry === 'function') {
            transferFilter(items[i].webkitGetAsEntry());
          }
        }
      }
    },
    [isFileDialogActive, transferFilter],
  );

  const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { files } = e.target;
    for (let i = 0; i < files.length; i++) {
      createQueue(files[i], files[i].name);
    }

    if (isFileDialogActive) {
      setIsFileDialogActive(false);
    }
    // allows onChange to fire when previously selected file is selected again
    e.target.value = '';
  };

  const clickHandler = () => {
    setIsFileDialogActive(true);
  };

  const reload = useCallback(
    (item) => {
      if (item.file instanceof File) {
        dispatch(supplierDocumentUploadAction(item));
      }
    },
    [dispatch],
  );

  const uploadFromQueue = useCallback(() => {
    setIsUploading(true);
    queue.forEach((item) => {
      if (item.status !== 'pending') return;
      dispatch(
        supplierDocumentUploadAction(
          item.set(
            'params',
            paramsAdapter({
              creationDate: new Date(),
              uploadText: text,
              subject: subjectText,
              ccEmailList: bkList.map(({ value }) => value),
              // can be linked if the number of files is more than 1
              ...(queue.size > 1 && { linkID }),
            }),
          ),
        ),
      );
    });
  }, [dispatch, queue, bkList, text, subjectText, linkID]);

  useEffect(() => {
    if (isUploading && !queue.size) {
      close();
    }
  }, [queue.size, close, isUploading]);

  const uploaderBody = (
    <>
      <Box borderRadius="50%" bgcolor={lightBlue[50]} p={1} dispaly="flex" lineHeight={0}>
        <UploadFileIcon color="primary" />
      </Box>
      <Typography variant="subtitle1">
        {formatMessage({ id: 'document.upload.dropFilesToUploadOr', defaultMessage: 'Drop files to upload ' })}
        <UploadText>
          {formatMessage({ id: 'document.upload.chooseFromComputer', defaultMessage: 'or choose from computer' })}
        </UploadText>
      </Typography>
      <Typography variant="caption" color="text.disabled" textAlign="center">
        {formatMessage({ id: 'document.upload.maxSize', defaultMessage: 'The maximum file size supported is 64 MB' })}{' '}
        <br />
        {formatMessage({ id: 'document.upload.weSupportFormats', defaultMessage: '.docx, .xlsm, .xlsx, .pdf, .jpg' })}
      </Typography>
      <FileInput
        type="file"
        hidden
        ref={fileInputElRef}
        accept={acceptTypes.join(', ')}
        id="dropzone"
        multiple
        onChange={handleChange}
        onClick={clickHandler}
      />
    </>
  );

  const accountantsOption = useMemo(
    () => accountants.map(({ username, userId, picture }) => ({ value: userId, label: username, picture })),
    [accountants],
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      title={formatMessage({ id: 'document.upload.uploadDocument', defaultMessage: 'Upload Documents' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
    >
      <DialogContent>
        <Stack direction="row" py={1} alignItems="center" spacing={1} pb={0}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            {formatMessage({ id: 'company.supplier.upload.label.to', defaultMessage: 'To' })}:
          </Typography>
          <Stack direction="row" bgcolor={lightBlue[50]} py={0.5} pl={0.5} pr={2} borderRadius={1}>
            <Avatar src={companyLogo} name={companyName} size={20} />
            <Typography variant="caption">{companyName}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" py={1} alignItems="baseline" mt={1} spacing={1} pb={0}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            {formatMessage({ id: 'company.supplier.upload.label.from', defaultMessage: 'From' })}:
          </Typography>
          <Typography variant="caption">{email}</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" alignItems="baseline" spacing={1}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            {formatMessage({ id: 'company.supplier.upload.label.cc', defaultMessage: 'CC' })}:
          </Typography>
          <Autocomplete options={accountantsOption.toJS()} onSelectChange={onSelectChange} />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" py={1} alignItems="baseline" mt={1} spacing={1} pb={0}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            {formatMessage({ id: 'company.supplier.upload.label.subject', defaultMessage: 'Subject' })}:
          </Typography>
          <SubjectInput onChange={onSubjectText} value={subjectText} />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Box mt={1}>
          <FormattedMessage id="company.supplier.upload.msg.placeholder" defaultMessage="Type text...">
            {(msg) => <MessageInput value={text} onChange={onChangeText} placeholder={msg} />}
          </FormattedMessage>
        </Box>
        <Dropzone htmlFor="dropzone" onDndDrop={onDndDrop} onDrop={onDrop}>
          {uploaderBody}
        </Dropzone>
        {!!queue.size && <Queue queue={queue} remove={remove} reload={reload} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="text">
          {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button onClick={uploadFromQueue} disabled={!queue.size || !isPendingUpload}>
          {formatMessage({ id: 'company.supplier.bar.btns.sendDocuments', defaultMessage: 'Send Documents' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Upload;
