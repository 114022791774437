// @flow
/* eslint-disable max-len */
import { selector } from 'lib/selectors';
import { List } from 'immutable';
// other selectors
import { userGUIDSelector } from 'domain/env/envSelector';
import { documentApprovalStampPositionSelector } from 'domain/documents/selectors/getDocumentApprovalStampPositionSelector';
// import { isDocumentConfidentialSelector } from 'domain/documents/documentSelector';
// import { organizationUsersSelector } from 'domain/organizationUser/selectors';
// types
import type { DokkaStore } from '../types.js.flow';
import type {
  ApprovalsRecord,
  UserEnhancedApprovalRecord,
  ApprovalRecordList,
  ApprovalFlowStatus,
  ApprovalReasonRecordMap,
  FormFields,
  ApprovalGroupsType,
  AdvancedApprovalGroupsType,
  ApprovalRecordLevelsList,
  ApproverType,
} from 'domain/approvals/types.js.flow';
import type { TUiAFStampMode } from 'domain/ui/types.js.flow';
import type { TApprovalStampStore } from 'domain/documents/types.js.flow';
// factories
import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';
import { ApprovalLevelsListRecord, ApprovalLevelsWithUserData } from 'domain/approvals/types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;

// this can not be imported from reconciliation
export const approvals = (state: any): ApprovalsRecord => state.approvals;
export const approvalsListSelector: SelectorType<ApprovalRecordList> = selector(
  approvals,
  (a: ApprovalsRecord) => a.list,
);
export const approvalsLevelsListSelector: SelectorType<any> = selector(approvals, (a: ApprovalsRecord) => a.levelsList);
export const approvalsAuthorSelector: SelectorType<string> = selector(approvals, (a: ApprovalsRecord) => a.author);
export const approvalsEditableSelector: SelectorType<string> = selector(approvals, (a: ApprovalsRecord) => a.editable);
export const approvalsSignatureSelector: SelectorType<string> = selector(
  approvals,
  (a: ApprovalsRecord) => a.signature,
);
export const approvalsCurrentSignersSelector: SelectorType<Array<string>> = selector(
  approvals,
  (a: ApprovalsRecord) => a.currentSigners,
);
export const approvalsStatusSelector: SelectorType<ApprovalFlowStatus> = selector(
  approvals,
  (a: ApprovalsRecord) => a.status,
);
export const approvalsStatusAsBoolSelector: SelectorType<boolean> = selector(
  approvals,
  (a: ApprovalsRecord) => a.status !== 'draft' && a.status !== null,
);
export const isCurrentUserSignaturePendingSelector: SelectorType<string> = selector(
  approvalsCurrentSignersSelector,
  userGUIDSelector,
  approvalsStatusAsBoolSelector,
  (signersPending: string, currentUserGuid: string, isFlowActive: boolean) =>
    signersPending.includes(currentUserGuid) && isFlowActive,
);
export const isSignApprovalsStartSelector: SelectorType<boolean> = selector(
  approvalsListSelector,
  approvalsLevelsListSelector,
  (approvalList: ApprovalRecordList, approvalLevels: ApprovalRecordLevelsList) => {
    const firstLevel = approvalLevels.first();
    const first = approvalList.first();

    const firstApproved = first ? first.get('status') === 'approved' : false;
    const firstApprovedOnLevel = firstLevel ? firstLevel.approvers.some((a) => a.status === 'approved') : false;

    return firstApproved || firstApprovedOnLevel;
  },
);

export const approversSelector: SelectorType<List<ApproverType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approvers,
);

export const userEnhancedApprovalsListSelector: SelectorType<List<UserEnhancedApprovalRecord>> = selector(
  approvalsListSelector,
  approversSelector,
  (approvalList: ApprovalRecordList, approvers: List<ApproverType>) =>
    approvalList.map((approval) => {
      const approver = approvers.find((u) => u.username === approval.email);
      return UserEnhancedApprovalFactory({
        userGuid: approval.userGuid,
        tag: approval.email,
        id: approval.id,
        picture: approval.picture,
        status: approval.status,
        fullName: approver?.fullName || approval.email,
      });
    }),
);

export const approvalsRejectReasonsSelector: SelectorType<ApprovalReasonRecordMap> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectReasonsList,
);

export const approvalsRejectReasonIdSelector: SelectorType<?string> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectReason,
);

export const approvalsRejectReasonDetailsSelector: SelectorType<?string> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectMessage,
);

export const approvalsRejectReasonTextSelector: SelectorType<?string> = selector(
  approvalsRejectReasonIdSelector,
  approvalsRejectReasonsSelector,
  (id: ?string, list: ApprovalReasonRecordMap) => (id ? list.get(id, null) : null),
);

export const approveFormFieldsListSelector: SelectorType<FormFields> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approveFormFieldsList,
);

export const getApprovalStampModeByApprovals: SelectorType<TUiAFStampMode> = selector(
  documentApprovalStampPositionSelector,
  approvalsListSelector,
  approvalsStatusSelector,
  approvalsLevelsListSelector,
  (
    approvalStampPosition: TApprovalStampStore,
    list: ApprovalRecordList,
    status: ApprovalFlowStatus,
    approvalLevels: ApprovalRecordLevelsList,
  ) => {
    // If the AF status is not active and there is no stamp on the document and list of approver are greater than zero -
    // we can edit the position of the stamp
    if (
      approvalStampPosition.toSeq().every((value) => value === null) &&
      (list.size > 0 || approvalLevels.size > 0) &&
      status !== 'active'
    ) {
      return 'edit';
    }

    return 'hidden';
  },
);

export const approvalGroupListSelector: SelectorType<List<ApprovalGroupsType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approvalGroups,
);

export const approvalGroupsLoadedSelector: SelectorType<boolean> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approvalGroupsLoaded,
);

const approvalActiveGroupIdSelector: SelectorType<List<ApprovalGroupsType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.groupId,
);

export const approvalActiveAdvancedGroupIdSelector: SelectorType<string> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.advancedGroupId,
);

export const activeGroupNameEnhancedSelector: SelectorType<?string> = selector(
  approvalActiveGroupIdSelector,
  approvalGroupListSelector,
  (activeGroupId: string, groupsList: List<ApprovalGroupsType>) => {
    const groupName = groupsList.find((g) => g.group_id === activeGroupId);
    return groupName ? groupName.group_title : null;
  },
);

export const approversLoadedSelector: SelectorType<boolean> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approversLoaded,
);

export const advancedApprovalGroupListSelector: SelectorType<List<AdvancedApprovalGroupsType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.advancedApprovalGroups,
);

export const advancedApprovalGroupsLoadedSelector: SelectorType<boolean> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.advancedApprovalGroupsLoaded,
);

export const activeAdvancedGroupNameEnhancedSelector: SelectorType<?string> = selector(
  approvalActiveAdvancedGroupIdSelector,
  advancedApprovalGroupListSelector,
  (activeAdvancedGroupId: string, advancedGroupsList: List<AdvancedApprovalGroupsType>) => {
    const groupName = advancedGroupsList.find((g) => g.id === activeAdvancedGroupId);
    return groupName ? groupName.name : null;
  },
);

export const userEnhancedApprovalsLevelsListSelector: SelectorType<ApprovalLevelsWithUserData> = selector(
  approvalsLevelsListSelector,
  approversSelector,
  (approvalLevels: ApprovalRecordLevelsList, approvers: List<ApproverType>) =>
    approvalLevels
      .map(({ approvers: approversNodes, minAmount, minApprovers, flowType, status }) => ({
        minAmount,
        minApprovers,
        flowType,
        status,
        approvers: approversNodes.map((approval) => {
          const approver = approvers.find((u) => u.username === approval.username);
          return UserEnhancedApprovalFactory({
            userGuid: approval.username,
            tag: approval.username,
            id: approval.userid,
            picture: approver?.picture,
            status: approval.status,
            fullName: approver?.fullName || approval.username,
          });
        }),
      }))
      .toJS(),
);

// export const organizationUsersForConfidentialDocSelector: SelectorType<ChatUserRecords> =
//   selector(state => state.chat.users, isDocumentConfidentialSelector,
//     (users: ChatUserRecords, isDocumentConfidential: boolean) => (!isDocumentConfidential ? users : users.filter(
//       user => user.role !== 'user', // user role doesnt have access to confidentialdocs
//     )),
//   );
