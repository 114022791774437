import IconButton from '@mui/material/IconButton';
import { darken, styled } from '@mui/material/styles';

export const ColumnVisibilityButton = styled(IconButton, { label: 'ColumnVisibilityButton' })(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.1),
  },

  '& .MuiSvgIcon-root': {
    fill: theme.palette.common.white,
  },
}));
