// @flow
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { currentCompanyDateFormatSelector } from 'domain/companies/companiesSelector';

import Typography from '@mui/material/Typography';

type TProps = {
  value: string,
}
const DateCell: React$StatelessFunctionalComponent<TProps> = ({ value }) => {
  const companyDateFormat = useSelector(currentCompanyDateFormatSelector);
  const dateFormat = companyDateFormat || 'DD/MM/YYYY';

  const date = moment(value).isValid() ? moment(value).format(dateFormat) : value;

  return <Typography variant="body2">{date}</Typography>;
};

export default DateCell;
