// @flow
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { reduxForm, Field, type FormProps } from 'redux-form/immutable';
import type { OrderedSet } from 'immutable';

import { getDocumentsApprovers } from 'domain/documents';
import { approversSelector } from 'domain/approvals/selectors';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import UserField from './components/UserField';
import Stack from '@mui/material/Stack';

import { addError, rules } from 'components/Form/validation';

type TReplaceApproverForm = {
  onCancel: () => void,
  selectedIDs: OrderedSet<string>,
} & FormProps;

const ReplaceApproverForm: React$StatelessFunctionalComponent<TReplaceApproverForm> = ({
  handleSubmit,
  invalid,
  submitting,
  onCancel,
  selectedIDs,
}) => {
  const { formatMessage } = useIntl();

  const allApprovers = useSelector(approversSelector);
  const documentsApprovers = useSelector(getDocumentsApprovers(selectedIDs));
  const options = allApprovers.toJS().sort((a, b) => a.fullName.localeCompare(b.fullName)).map(({ username }) => username);
  const emails = useMemo(() => [...new Set([...options])], [options]);
  const memoApprovers = useMemo(
    () => allApprovers.reduce((res, approver) => ({ ...res, [approver.username]: approver }), {}),
    [allApprovers],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Field
            component={UserField}
            label={formatMessage({ id: 'form.dialog.replaceApprover.replace', defaultMessage: 'Replace' })}
            placeholder={formatMessage({ id: 'form.dialog.replaceApprover.replace', defaultMessage: 'Replace' })}
            required
            name="replace"
            fullWidth
            autoFocus
            emailOptions={documentsApprovers}
            approvers={memoApprovers}
          />
          <Field
            component={UserField}
            label={formatMessage({ id: 'form.dialog.replaceApprover.with', defaultMessage: 'With' })}
            placeholder={formatMessage({ id: 'form.dialog.replaceApprover.with', defaultMessage: 'With' })}
            required
            name="to"
            fullWidth
            emailOptions={emails}
            approvers={memoApprovers}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={submitting} disabled={invalid || submitting}>
          {formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const validator = (values) => (errors, fieldName) => {
  const val = values.get(fieldName, '');
  const replaceValue = values.get('replace', '');
  switch (fieldName) {
    case 'replace':
      return compose(addError(fieldName, rules.required(val[0])))(errors);

    case 'to':
      return compose(
        addError(fieldName, rules.required(val[0])),
        addError(fieldName, rules.difference(val[0], replaceValue[0])),
      )(errors);

    default:
      return errors;
  }
};

const validate = (values) => ['replace', 'to'].reduce(validator(values), {});
export default compose(
  reduxForm({
    form: 'replaceApproverForm',
    validate,
  }),
)(ReplaceApproverForm);
