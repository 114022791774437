import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';
import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

export const Fields = {
  name: 'group_title',
  approvers: 'approvers[]',
};

export const HeadersAdapter = ({ formatMessage, handleEditGroup, handleDeleteGroup, disableEdit, disableDelete }) => {
  const headers = [
    {
      field: Fields.name,
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.name',
        defaultMessage: 'Group Name',
      }),
      type: ColumnTypes.text,
      minWidth: 200,
      maxWidth: 450,
    },
    {
      field: Fields.approvers,
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.approvers',
        defaultMessage: 'Approvers',
      }),
      cellRenderer: ColumnTypes.approvals,
    },
    {
      field: 'date',
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.date',
        defaultMessage: 'Date',
      }),
      type: ColumnTypes.date,
    },
    {
      field: 'owner',
      headerName: formatMessage({
        id: 'configurations.company.approval_advancedGroups.table.column.owner',
        defaultMessage: 'Owner',
      }),
      cellRenderer: ColumnTypes.user,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: 'controls',
      cellRenderer: ColumnTypes.controls,
      width: 120,
      maxWidth: 120,
      cellRendererParams: {
        handleEditClick: handleEditGroup,
        handleDeleteClick: handleDeleteGroup,
        disableEdit,
        disableDelete,
      },
    },
  ];

  return headers;
};

const ApproverFactory = ({ group_title, approvers, ...rest }) => ({
  [Fields.name]: group_title,
  [Fields.approvers]: approvers,
  ...rest,
});

const RowFactory = (value) => ({
  ...ApproverFactory(value),
  [ColumnTypes.controls]: ApproverFactory(value),
  [ColumnTypes.date]: value.created_at,
  owner: value.owner,
});

const RowAdapter = (group, companyApprovers, organizationUsers) =>
  RowFactory({
    ...group,
    id: group.group_id,
    approvers: group.approvers.reduce((a, v) => {
      const approver = companyApprovers.find((u) => u.id === v);
      if (approver)
        return [
          ...a,
          UserEnhancedApprovalFactory({
            userGuid: approver.username,
            tag: v.username,
            id: approver.id,
            picture: approver.picture,
            fullName: approver.fullName,
          }).toJS(),
        ];
      return a;
    }, []),
    owner: organizationUsers.reduce((a, v) => {
      if (v.id === group.owner) {
        return UserEnhancedApprovalFactory({
          userGuid: v.email,
          tag: v.email,
          id: v.id,
          picture: v.pic,
          fullName: `${v.first_name} ${v.last_name}`,
        }).toJS();
      }
      return a;
    }, UserEnhancedApprovalFactory().toJS()),
  });

export const RowsAdapter = (groups, companyApprovers, organizationUsers) => groups.toJS().map((g) => RowAdapter(g, companyApprovers, organizationUsers));
