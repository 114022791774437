import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export const STable = styled(Table)(() => ({
  borderCollapse: 'separate',
  borderSpacing: '0px 8px',
}));

export const STableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

export const STableHeaderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(1.5, 0, 0, 3),
}));

export const STableCell = styled(TableCell)(({ theme }) => {
  const radius = 8;
  const color = theme.palette.grey[300];
  return {
    borderBottom: `1px solid ${color}`,
    borderTop: `1px solid ${color}`,
    padding: theme.spacing(1.5, 0, 1.5, 3),
    '&:first-of-type': {
      border: `1px solid ${color}`,
      borderRight: 'none',
      borderLeftStyle: 'solid',
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
    },
    '&:last-of-type': {
      border: `1px solid ${color}`,
      borderLeft: 'none',
      borderRightStyle: 'solid',
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
    },

    // spanned cell for display 'No rows' message now
    '&:only-child': {
      border: `1px solid ${color}`,
      borderRadius: radius,
      textAlign: 'center',
    },
  };
});
