// @flow
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { localeSelector } from 'domain/env';
import download from 'lib/download';
import { useApiToken } from 'lib/apiTokenKeeper';
import Api from 'domain/api';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

type TProps = {
  sectionName?: string,
};

const ButtonExport: React$StatelessFunctionalComponent<TProps> = ({ sectionName }) => {
  const dokkaToken = useApiToken();
  const { companyId } = useParams();
  const language = useSelector(localeSelector);
  const endpoint = sectionName ? Api.exportVendorPreferencesNew : Api.exportVendorPreferences;

  const downloadFile = useCallback(async () => {
    await download(endpoint({ dokkaToken, company_id: companyId, language, sectionName }));
  }, [dokkaToken, companyId, language, sectionName, endpoint]);
  return (
    <Button onClick={downloadFile} variant="outlined" startIcon={<CloudDownloadIcon />}>
      <FormattedMessage id="button.export.file" defaultMessage="Export" />
    </Button>
  );
};

export default ButtonExport;
