// @flow
import React, { useCallback, memo, useState, useEffect } from 'react';

import {
  MultipleAutocompleteVirtualized,
  TextField,
} from 'pages/company/Grid/components/MultipleDropdownCellEditor/StyledComponents';
import Chip from '@mui/material/Chip';

import type { TOption, TOptions } from 'components/mui/Form/Autocomplete/AutocompleteBase';

type TProps = {
  column: any,
  onValueChange: (TOption | {}) => void,
  value: Array<string>,
  options: TOptions,
};

const MultipleDropdownCellEditor: React$StatelessFunctionalComponent<TProps> = ({
  options,
  column: { actualWidth },
  onValueChange,
  value,
}) => {
  const [adaptedValues, setAdaptedValues] = useState<TOption[]>([]);

  useEffect(() => {
    const data = value?.reduce((acc, v) => {
      const option = options.find((o) => o.id === v);

      if (option) {
        acc.push(option);
      }

      return acc;
    }, []);

    setAdaptedValues(data);
  }, [options, value]);

  const handleChange = useCallback(
    (e, selected) => {
      const data = selected.map((o) => o?.id || o);

      onValueChange(data);
    },
    [onValueChange],
  );

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip key={option?.id} label={option?.label} size="small" {...getTagProps({ index })} />
      )),
    [],
  );

  return (
    <MultipleAutocompleteVirtualized
      open
      value={adaptedValues}
      options={options}
      onChange={handleChange}
      isOptionEqualToValue={(listOption, option) => (option ? listOption.id === option.id : false)}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" autoFocus />
      )}
      ListboxProps={{ optionStyle: { unicodeBidi: 'normal' }, suppressToolTip: false }}
      renderTags={renderTags}
      sx={{ width: actualWidth }}
      fullWidth
      autoComplete
      disablePortal
      disableCloseOnSelect
      multiple
      filterSelectedOptions
    />
  );
};

export default memo(MultipleDropdownCellEditor);
