/* @flow */
import * as React from 'react';
import cx from 'classnames';

import { withStyles } from '@mui/styles';
import sheet from './sheet';
import { FormattedMessage } from 'react-intl';
import type { TLabelOptions } from 'labels/type.js.flow';
import flow from 'lodash/flow';

export type P = {
  classes: {|
    [key: string]: string,
  |},
  className: string,
  masterDetailView: boolean,
  theme: {
    labelOptions: TLabelOptions,
  },
};

const JEError: React.StatelessFunctionalComponent<P> = ({ classes, className, masterDetailView = false, theme: { labelOptions } }: P) => (
  <div className={cx(classes.box, className, { [classes.verticalMiddleAlignment]: masterDetailView })}>
    <div className={classes.title}>
      <FormattedMessage id="document.journalEntry.error.title" defaultMessage="Something went wrong" />
    </div>
    <div className={classes.mesage}>
      {!masterDetailView ?
        <FormattedMessage
          id="document.journalEntry.error.message"
          defaultMessage="The data entry form is not available due to a connectivity or other issue.
          Please, click {sync} button at the top-right of the screen  or contact {email}"
          values={{
            sync: <span className={classes.sync}>Sync</span>,
            email: (
              <a href={`mailto:${labelOptions.supportEmail}`} className={classes.email}>
                {labelOptions.supportEmail}
              </a>
            ),
          }}
        /> :
        <FormattedMessage
          id="document.journalEntry.error.shortMessage"
          defaultMessage="The data entry form is not available due to a connectivity or other issue."  
        />
      }
    </div>
  </div>
);

export default flow(withStyles(sheet, { withTheme: true }))(JEError);
