import moment from 'moment';
import type { GridHeaderItemType, GridRawHeaderItemType, TOperators } from 'domain/documents/types.js.flow';

const OPERATORS = {
  eq: 'equals',
  neq: 'notEqual',
  contains: 'contains',
  notContains: 'notContains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  blank: 'blank',
  notBlank: 'notBlank',
  gt: 'greaterThan',
  gte: 'greaterThanOrEqual',
  lt: 'lessThan',
  lte: 'lessThanOrEqual',
  inrange: 'inRange',
  afterOrOn: 'afterOrOn',
  beforeOrOn: 'beforeOrOn',
};

const withCustomOperators = {
  ...OPERATORS,
  afterOrOn: {
    displayKey: 'afterOrOn',
    displayName: 'After or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrAfter(moment(filterValue).startOf('day')),
  },
  beforeOrOn: {
    displayKey: 'beforeOrOn',
    displayName: 'Before or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrBefore(moment(filterValue).startOf('day')),
  },
};

const operatorsByType = {
  date: ['beforeOrOn', 'afterOrOn', 'inrange'],
  string: ['contains', 'notContains', 'startsWith', 'endsWith', 'blank', 'notBlank'],
  amount: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'inrange'],
  integer: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'inrange'],
};

const DEFAULT_OPTION_LIST = {
  string: OPERATORS.contains,
  number: OPERATORS.eq,
  amount: OPERATORS.eq,
  integer: OPERATORS.eq,
  date: OPERATORS.beforeOrOn,
};

const DEFAULT_FILTER_TYPE = 'string';

export const buildFilterMapping = (filterMapping = []) =>
  filterMapping.reduce((acc, v) => {
    const [type, filter] = Object.entries(v)[0];
    const operators = operatorsByType[type];

    acc[type] = {
      ...filter,
      filterParams: {
        ...(filter.filterParams || {}),
        defaultOption: DEFAULT_OPTION_LIST[type],
        filterOptions: operators && operators.map((operator) => withCustomOperators[operator]),
        showTooltips: true,
      },
    };

    return acc;
  }, {});

const createFiltersWithDefaultOption = (
  column: GridRawHeaderItemType,
  filterByTypeOrDefault,
  operator: ?TOperators,
) => {
  const { filter, filterParams } = column;

  // enableFilterButton managed in new column menu by suppressHeaderFilterButton props
  // ag-grid docs - Set to true to not display the filter button in the column header. Doesn't apply when columnMenu = 'legacy'.
  // so for the legacy menu, simulate behavior of new column menu and control with the b-end to show or hide the filter icon
  const headerComponentParams = { enableFilterButton: column.filter };

  return {
    filter: filter === true ? filterByTypeOrDefault.filter : false, // if b-end filter === false - turn off filter for column, otherwise apply mapping by column type
    filterParams: {
      ...(filterByTypeOrDefault.filterParams || {}), // f-end filter params
      ...(filterParams || {}), // b-end filter params - has a greater weight than f-end
      ...(operator && { defaultOption: OPERATORS[operator] }),
      closeOnApply: true,
    },
    headerComponentParams: {
      ...column?.headerComponentParams,
      ...headerComponentParams,
    },
  };
};

export const addFilters =
  (filterMapping) =>
  (columns: GridHeaderItemType[]): Array<*> =>
    columns.map(({ operator: columnOperator, ...column }) => {
      const operators = operatorsByType[column.type];
      const operator: ?TOperators = (operators && operators.includes(columnOperator) && columnOperator) || undefined;
      const filterByTypeOrDefault = filterMapping[column.type] || filterMapping[DEFAULT_FILTER_TYPE];
      const filter = createFiltersWithDefaultOption(column, filterByTypeOrDefault, operator);

      return {
        ...column,
        ...filter,
      };
    });
