// @flow
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { GRID_SERVICE_KEYS } from 'components/AgGrid/helpers';
import { useIntl } from 'react-intl';
import type { TGridApi, Column, TSelectPosition } from 'pages/company/Grid/types.js.flow';

import Tooltip from 'components/mui/Tooltip';
import Box from '@mui/material/Box';
import { ColumnVisibilityButton } from 'components/AgGrid/components/ColumnHeader/StyledComponents';

import EditIcon from '@mui/icons-material/Edit';
import { ReactComponent as SortIcon } from 'components/AgGrid/components/ColumnHeader/icons/sort-default.svg';
import { ReactComponent as SortAcsIcon } from 'components/AgGrid/components/ColumnHeader/icons/sort-asc.svg';
import { ReactComponent as SortDescIcon } from 'components/AgGrid/components/ColumnHeader/icons/sort-desc.svg';

import cx from 'classnames';
import useStyles from 'components/AgGrid/components/ColumnHeader/sheet';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  api: TGridApi,
  column: Column,
  showColumnMenu: (menuButton: HTMLElement) => void,
  showFilter: (menuButton: HTMLElement) => void,
  setSort: (sort: string, multiSort?: boolean) => void,
  enableMenu: boolean,
  enableSorting: boolean,
  enableFilterButton: boolean,
  enablePinning?: boolean, // manual props via headerComponentParams for  hide/show pin icon
  headerTooltip?: boolean, // manual props via headerComponentParams to control toltip behaviour
  suppressHeaderRenderer?: boolean, // manual props via headerComponentParams
  displayName: string,
};

const { COLUMNS_VISIBILITY_MENU } = GRID_SERVICE_KEYS;

const SORT_TYPES = {
  asc: 'asc',
  desc: 'desc',
  no: '',
};

const prepareColumnData = (rawData) => {
  const { columns, startRow, endRow } = rawData;
  return {
    columns,
    rowStartPinned: startRow.rowPinned,
    rowEndPinned: endRow.rowPinned,
    rowStartIndex: startRow.rowIndex,
    rowEndIndex: endRow.rowIndex,
  };
};

const columnSelect = (api, column) => {
  const getRowEndIndex = () => api.getDisplayedRowCount() - 1;

  const isSelectedColumn = (el: TSelectPosition) => {
    const { startRow, endRow, columns } = el;
    return startRow.rowIndex === 0 && endRow.rowIndex === getRowEndIndex() && columns[0]?.colId === column.colId;
  };

  const selectColumn = (isModifierPressed) => {
    if (!isModifierPressed) {
      api.clearCellSelection();
    }
    api.addCellRange({
      columnStart: column.colId,
      columnEnd: column.colId,
      rowStartIndex: 0,
      rowEndIndex: getRowEndIndex(),
    });
  };

  const deselectColumn = () => {
    const filteredRanges = api.getCellRanges().filter((el) => !isSelectedColumn(el));
    api.clearCellSelection();
    filteredRanges.forEach((el) => {
      api.addCellRange(prepareColumnData(el));
    });
  };

  const onClickColumn = (e) => {
    const isModifierPressed = e.ctrlKey || e.metaKey;
    const isSelected = api.getCellRanges().some((el) => isSelectedColumn(el));
    isSelected ? deselectColumn() : selectColumn(isModifierPressed);
  };
  return onClickColumn;
};

const AgGridColumnHeader = (props: Props): React$Node => {
  const {
    api,
    column,
    showColumnMenu,
    showFilter,
    setSort,
    enableMenu,
    enableSorting,
    enablePinning = true,
    enableFilterButton,
    displayName,
    headerTooltip = false,
    suppressHeaderRenderer = false,
  } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [sortType, setSortType] = useState(SORT_TYPES.no);
  const [isFilterActive, setIsFilterActive] = useState(column.isFilterActive());
  const refButton = useRef(null);
  const refPinButton = useRef(null);
  const refFilterButton = useRef(null);

  const onClickColumn = useMemo(() => columnSelect(api, column), [api, column]);

  const handlePinButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      column.setColDef({ ...column.colDef, menuTabs: ['generalMenuTab'] }, column.userProvidedColDef);
      showColumnMenu(refPinButton.current);
    },
    [showColumnMenu, column],
  );

  const handleFilterButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      showFilter(refFilterButton.current);
    },
    [showFilter],
  );

  const onMenuClicked = useCallback(() => {
    column.setColDef({ ...column.colDef, menuTabs: ['columnsMenuTab', 'generalMenuTab'] }, column.userProvidedColDef);
    showColumnMenu(refButton.current);
  }, [column, showColumnMenu]);

  const onSortChanged = useCallback(() => {
    const isSortAscending = column.isSortAscending();
    const isSortDescending = column.isSortDescending();

    const initialSort = (isSortAscending && SORT_TYPES.asc) || (isSortDescending && SORT_TYPES.desc) || SORT_TYPES.no;
    setSortType(initialSort);
  }, [column]);

  const onSortRequested = useCallback(
    (order, event) => {
      setSort(order, event.shiftKey);
    },
    [setSort],
  );

  const handleMenuClicked = useCallback(
    (event) => {
      event.stopPropagation();
      onMenuClicked();
    },
    [onMenuClicked],
  );

  const handleSort = useCallback(
    (event) => {
      event.stopPropagation();
      switch (sortType) {
        case SORT_TYPES.no:
          onSortRequested(SORT_TYPES.asc, event);
          break;
        case SORT_TYPES.asc:
          onSortRequested(SORT_TYPES.desc, event);
          break;
        case SORT_TYPES.desc:
          onSortRequested(SORT_TYPES.no, event);
          break;
        default:
          break;
      }
    },
    [onSortRequested, sortType],
  );

  useEffect(() => {
    column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChanged = useCallback(() => {
    setIsFilterActive(column.isFilterActive());
  }, [column]);

  // TODO: ag-grid not sure that useEffects are needed
  useEffect(() => {
    column.addEventListener('filterChanged', onFilterChanged);
    onFilterChanged();
    return () => {
      column.removeEventListener('filterChanged', onFilterChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sort = useMemo(() => {
    if (!enableSorting) return null;
    return (
      <button className={classes.sortOptions} onClick={handleSort} onTouchEnd={handleSort} type="button">
        {{
          [SORT_TYPES.asc]: <SortAcsIcon className={classes.sortIcon} />,
          [SORT_TYPES.desc]: <SortDescIcon className={classes.sortIcon} />,
        }[sortType] || <SortIcon className={classes.sortIcon} />}
      </button>
    );
  }, [enableSorting, sortType, classes, handleSort]);

  const columnVisibilityMenu = useMemo(() => {
    if (!enableMenu) return null;

    return (
      <Tooltip t={formatMessage({ id: 'tooltip.editColumns', defaultMessage: 'Edit Columns' })}>
        <ColumnVisibilityButton
          ref={refButton}
          onClick={handleMenuClicked}
          size="small"
          color="primary"
          sx={{ mx: 'auto' }}
        >
          <EditIcon fontSize="small" />
        </ColumnVisibilityButton>
      </Tooltip>
    );
  }, [enableMenu, handleMenuClicked, formatMessage]);

  if (suppressHeaderRenderer) return null;

  return (
    <div className="ag-cell-label-container" role="presentation" onClick={onClickColumn}>
      {COLUMNS_VISIBILITY_MENU === column.colId && columnVisibilityMenu}
      {column.colId !== COLUMNS_VISIBILITY_MENU && (
        <>
          <Box display="flex" alignItems="center">
            {enablePinning && (
              <span
                role="presentation"
                ref={refPinButton}
                onClick={handlePinButtonClick}
                className={cx('ag-icon', 'ag-icon-pin', classes.headerButton, {
                  [classes.activeButton]: column.pinned,
                })}
              />
            )}
            {enableFilterButton && (
              <span
                role="presentation"
                ref={refFilterButton}
                onClick={handleFilterButtonClick}
                className={cx('ag-icon', 'ag-icon-filter', classes.headerButton, {
                  [classes.activeButton]: isFilterActive,
                })}
              />
            )}
            {sort}
          </Box>

          <div className="ag-header-cell-label">
            <span className="ag-header-cell-text" role="columnheader" style={{ whiteSpace: 'nowrap' }}>
              <Tooltip t={displayName} disabled={!headerTooltip} placement="top">
                <span>{displayName}</span>
              </Tooltip>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

AgGridColumnHeader.displayName = 'ColumnHeader';

export default AgGridColumnHeader;
