import sync from './images/sync.svg';

export default (theme) => ({
  box: {},

  verticalMiddleAlignment: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  title: {
    fontSize: 34,
    textAlign: 'center',
  },

  mesage: {
    fontSize: 19,
    textAlign: 'center',
    margin: [30, '15%', 0, '15%'],
  },

  email: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.colors.primaryLight,
  },

  sync: {
    fontWeight: 'bold',
    color: theme.colors.primaryLight,
    cursor: 'inherit',
    border: '0 none',
    outline: 'none',
    paddingRight: 30,
    background: `url(${sync}) 90% 50% no-repeat`,
    backgroundColor: 'transparent',
  },
});
