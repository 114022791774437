// @flow
/* eslint-disable camelcase */
import { Record, List, Map, type RecordFactory } from 'immutable';

// types
import type {
  Approval,
  RawApproval,
  ApprovalRecordList,
  Approvals,
  RawApprovals,
  ApprovalsRecord,
  UserEnhancedApproval,
  ApprovalReason,
  ApprovalReasonRecordMap,
  FormField,
  Option,
  ApprovalGroupsType,
  PrevState,
  ApproverType,
} from './types.js.flow';
import { AdvancedApprovalGroupsLevelType, AdvancedApprovalGroupsType } from './types.js.flow';
import { LEVEL_FLOW_TYPE } from './constatns';

// factories
export const ApprovalItemFactory: RecordFactory<Approval> = new Record({
  userGuid: '',
  email: '',
  status: 'draft',
  picture: '', // default logo should be here
  id: null,
});

export type TagRecord = RecordFactory<{|
  tag: string,
  userGuid: string,
  status?: string,
  picture?: string,
|}>;

export const TagFactory: TagRecord = new Record({
  userGuid: '',
  tag: '',
  status: '',
  picture: '',
});

export const UserEnhancedApprovalFactory: RecordFactory<UserEnhancedApproval> = new Record({
  userGuid: '',
  tag: '',
  status: '',
  picture: '',
  fullName: '',
  id: null,
});

export const ApprovalsGroupFactory: RecordFactory<ApprovalGroupsType> = new Record({
  group_title: '',
  group_id: '',
  created_at: '',
  approvers: new List(),
  error: '',
  owner: null,
});

export const ApproverFactory: RecordFactory<ApproverType> = new Record({
  id: '',
  created_at: '',
  username: '',
  picture: null,
  role: '',
  fullName: '',
  phone: '',
});

export const AdvancedApprovalsGroupLevelFactory: RecordFactory<AdvancedApprovalGroupsLevelType> = new Record({
  approvers: new List(),
  minApprovers: '',
  minAmount: '',
  flowType: LEVEL_FLOW_TYPE.SEQUENTIAL,
  status: '',
});
export const AdvancedApprovalsGroupFactory: RecordFactory<AdvancedApprovalGroupsType> = new Record({
  id: '',
  name: '',
  owner: '',
  levels: new List(),
  created_at: '',
});
export const PrevStateFactory: RecordFactory<PrevState> = new Record({ list: new List() });

export const ApprovalsFactory: RecordFactory<Approvals> = new Record({
  list: new List(),
  levelsList: new List(),
  prevState: PrevStateFactory(),
  author: '',
  editable: false,
  dateCreated: '',
  currentSigners: [],
  signature: '',
  status: 'draft',
  rejectReason: null,
  rejectMessage: null,
  rejectReasonsList: Map(),
  approveFormFieldsList: List(),
  approvalGroups: new List(),
  advancedApprovalGroups: new List(),
  approvers: new List(),
  approvalGroupsLoaded: false,
  advancedApprovalGroupsLoaded: false,
  approversLoaded: false,
  rearrangingInProcess: 0,
  groupId: null,
  advancedGroupId: null,
});

export function ApprovalsListAdapter(items: Array<RawApproval> = []): ApprovalRecordList {
  return List(
    items.map(({ username, status, picture, userid, id }) =>
      ApprovalItemFactory({
        userGuid: userid,
        email: username,
        status,
        picture,
        id,
      }),
    ),
  );
}

export const ApprovalsGroupAdapter = (group: ApprovalGroupsType) =>
  ApprovalsGroupFactory({ ...group, approvers: new List(group.approvers) });

export const ApprovalGroupsAdapter = (groupsData: Array<ApprovalGroupsType> = []) =>
  new List(groupsData.map(ApprovalsGroupAdapter));

export const ApproverAdapter = (approver: ApproverType) =>
  ApproverFactory({ ...approver, fullName: approver.display_name });
export const ApproversAdapter = (approversData: Array<ApproverType> = []) =>
  new List(approversData.map(ApproverAdapter));

export const AdvancedApprovalsGroupLevelAdapter = ({
  min_amount: minAmount,
  min_approvers: minApprovers,
  flow_type: flowType,
  approvers,
  nodes,
  ...rest
}) => AdvancedApprovalsGroupLevelFactory({ ...rest, minAmount, minApprovers, flowType, approvers: approvers || nodes });
export const AdvancedApprovalsGroupLevelsAdapter = (levels: Array<AdvancedApprovalGroupsLevelType> = []) =>
  new List(levels.map(AdvancedApprovalsGroupLevelAdapter));
export const AdvancedApprovalGroupAdapter = (group: AdvancedApprovalGroupsType) =>
  AdvancedApprovalsGroupFactory({ ...group, levels: AdvancedApprovalsGroupLevelsAdapter(group.levels) });
export const AdvancedApprovalsGroupAdapter = (groupsData: Array<AdvancedApprovalGroupsType> = []) =>
  new List(groupsData.map(AdvancedApprovalGroupAdapter));

export function ApprovalRejectReasonListAdapter(items: { [key: string]: ApprovalReason }): ApprovalReasonRecordMap {
  return Map(items);
}

export const approvalFieldOptionFactory: RecordFactory<Option> = new Record({
  value: '',
  label: '',
});

export const approvalFieldFactory: RecordFactory<FormField> = new Record({
  group: '',
  label: '',
  name: '',
  type: '',
  value: '',
  mandatory: false,
  readonly: false,
  options: new List(),
  display_value: '',
});

export const approvalFieldOptionAdapter = (value: string, label: string) =>
  approvalFieldOptionFactory({ value, label });

export const approvalFieldsAdapter = (items: Array<FormField> = []) =>
  List(
    items.map((item) =>
      approvalFieldFactory({
        ...item,
        options: List(Object.entries(item.options || {}).map((option) => approvalFieldOptionAdapter(...option))),
      }),
    ),
  );

export function ApprovalsStoreAdapter(
  // $FlowFixMe ..rest is ok
  { nodes, updated, next, creator, rejectReasons, group_id, levels, advanced_group_id, ...rest }: RawApprovals = {},
): ApprovalsRecord {
  return ApprovalsFactory({
    ...rest,
    dateCreated: updated,
    currentSigners: Array.isArray(next) ? next : [next],
    author: creator,
    list: ApprovalsListAdapter(nodes || []),
    levelsList: AdvancedApprovalsGroupLevelsAdapter(levels || []),
    rejectReasonsList: ApprovalRejectReasonListAdapter(rejectReasons || {}),
    approveFormFieldsList: approvalFieldsAdapter([]),
    groupId: group_id,
    advancedGroupId: advanced_group_id,
  });
}
