// @flow
import React from 'react';

import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import elements from 'components/elements';

type TControls = {
  value: any,
  handleEditClick: () => void,
  handleDeleteClick: () => void,
  disableEdit?: boolean,
  disableDelete?: boolean,
};

const Controls: React$StatelessFunctionalComponent<TControls> = ({
  handleEditClick,
  handleDeleteClick,
  value,
  disableEdit,
  disableDelete,
}) => (
  <Stack direction="row" justifyContent="center">
    {!!handleEditClick && (
      <IconButton
        onClick={() => handleEditClick(value)}
        disabled={disableEdit}
        data-element={elements.configuration.company.approvals.table.controls.edit}
      >
        <EditOutlined />
      </IconButton>
    )}
    {handleDeleteClick && (
      <IconButton
        onClick={() => handleDeleteClick(value)}
        disabled={disableDelete}
        data-element={elements.configuration.company.approvals.table.controls.delete}
      >
        <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
      </IconButton>
    )}
  </Stack>
);

export default Controls;
