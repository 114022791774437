// @flow
import React, { useCallback, useState, useEffect } from 'react';
import { set } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from 'components/mui/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LevelItem from './components/LevelItem';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { Fields } from '../../helpers';
import {
  levelItemFactory,
  levelItemTouchedFactory,
  levelItemErrorFactory,
  formDataFactory,
  ErrorsFactory,
  TouchedFactory,
} from './helpers';

import type { AdvancedApprovalGroupsType } from 'domain/approvals/types';

import { alpha } from '@mui/material/styles';

import elements from 'components/elements';

type TAdvancedGroupDialog = {
  open: boolean,
  handleClose: () => void,
  title: string,
  onSubmit: (values: AdvancedApprovalGroupsType) => Promise<void>,
  initialValues: AdvancedApprovalGroupsType,
};

const AdvancedGroupDialog: React$StatelessFunctionalComponent<TAdvancedGroupDialog> = ({
  open,
  handleClose,
  title,
  onSubmit,
  initialValues,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [values, setValues] = useState(formDataFactory(initialValues));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = values[Fields.levels].findIndex((level) => level.id === active.id);
        const newIndex = values[Fields.levels].findIndex((level) => level.id === over.id);
        setValues({
          ...values,
          levels: arrayMove(values[Fields.levels], oldIndex, newIndex),
        });
      }
    },
    [values],
  );
  const [errors, setErrors] = useState(ErrorsFactory());
  const [touched, setTouched] = useState(TouchedFactory(initialValues));
  const [isSubmitting, setISSubmitting] = useState(false);

  const hasErrors = Object.values(errors)
    .flatMap((value) => (Array.isArray(value) ? value.flatMap(Object.values) : value))
    .some(Boolean);
  const enableSubmit = !hasErrors && !isSubmitting;

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    const newValue = typeof value === 'string' ? value.trimStart() : value;

    setValues((s) => set([name], newValue)(s));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setISSubmitting(true);
        await onSubmit(values);
        setTouched(TouchedFactory(values));
      } finally {
        setISSubmitting(false);
      }
    },
    [onSubmit, values],
  );

  const onBlur = useCallback((e) => {
    if (e.target.name) {
      setTouched((s) => set([e.target.name], true)(s));
    }
  }, []);

  const handleLevelChange = useCallback(
    (index, field, value) => {
      const updatedLevels = [...values[Fields.levels]];
      updatedLevels[index][field] = value;
      setValues({ ...values, [Fields.levels]: updatedLevels });
    },
    [values],
  );

  const addLevel = useCallback(() => {
    setValues({
      ...values,
      levels: [...values.levels, levelItemFactory()],
    });
    setTouched({
      ...touched,
      levels: [...touched.levels, levelItemTouchedFactory()],
    });
  }, [values, touched]);

  const removeLevel = useCallback(
    (index) => {
      const updatedLevels = values.levels.filter((_, i) => i !== index);
      const updatedTouchedLevels = touched.levels.filter((_, i) => i !== index);
      setValues({ ...values, levels: updatedLevels });
      setTouched({ ...errors, levels: updatedTouchedLevels });
    },
    [values, touched, errors],
  );

  useEffect(() => {
    const duplicateAmounts = values[Fields.levels]
      .map((level) => Number(level[Fields.minAmount]))
      .filter((amount, index, self) => self.indexOf(amount) !== index);

    setErrors(
      ErrorsFactory({
        [Fields.name]: !values[Fields.name]
          ? formatMessage({
              id: 'message.Group_title_can_not_be_empty',
              defaultMessage: 'Enter a name for this group',
            })
          : null,
        [Fields.levels]: values[Fields.levels].map((level) => {
          const minAmount = level[Fields.minAmount];
          const approvers = level[Fields.approvers];

          let minAmountError = null;
          if (minAmount === null) {
            minAmountError = 'required';
          } else if (duplicateAmounts.includes(Number(minAmount))) {
            minAmountError = formatMessage({
              id: 'message.unique_amounts_per_level_required',
              defaultMessage: 'Amounts must be unique for each level',
            });
          }

          const approversError = approvers.length ? null : 'should have at least one approval!';

          return levelItemErrorFactory({
            [Fields.minAmount]: minAmountError,
            [Fields.approvers]: approversError,
          });
        }),
      }),
    );
  }, [values, formatMessage]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={title}
      withActions={false}
      withContent={false}
      fullWidth
      maxWidth="lg"
      draggable={false}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogContent sx={{ paddingTop: 1 }}>
          <FormControl fullWidth error={Boolean(errors[Fields.name] && touched[Fields.name])} sx={{ mb: 2 }}>
            <TextField
              autoFocus
              multiline
              required
              disabled={isSubmitting}
              error={Boolean(errors[Fields.name] && touched[Fields.name])}
              value={values[Fields.name]}
              name={Fields.name}
              onChange={handleChange}
              onBlur={onBlur}
              label={formatMessage({
                id: 'configurations.company.approvals.advancedGroups.form.name.placeholder',
                defaultMessage: 'Group Name',
              })}
              type="text"
              fullWidth
              data-element={elements.popup.advancedApprovalGroups.groupName}
              inputProps={{
                maxLength: 128,
              }}
            />
            {!!errors[Fields.name] && touched[Fields.name] && (
              <FormHelperText
                data-element={elements.popup.advancedApprovalGroups.groupNameValidationError}
                error={Boolean(errors[Fields.name])}
              >
                {errors[Fields.name]}
              </FormHelperText>
            )}
          </FormControl>
          <Typography variant="body1" color={alpha(theme.palette.common.black, 0.6)} sx={{ mt: 4 }}>
            {formatMessage({
              id: 'configurations.company.approvals.advancedGroups.form.level.label',
              defaultMessage: 'Approval Levels',
            })}
          </Typography>
          <Box overflow="hidden">
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={values[Fields.levels].map((level) => level.id)} strategy={rectSortingStrategy}>
                {values[Fields.levels].map((level, i) => (
                  <LevelItem
                    key={level.id}
                    i={i}
                    levelData={level}
                    isSubmitting={isSubmitting}
                    setTouched={setTouched}
                    touched={touched}
                    errors={errors}
                    handleLevelChange={handleLevelChange}
                    removeLevel={removeLevel}
                    canBeDelete={values[Fields.levels].length !== 1}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Box>
          <Button
            variant="text"
            startIcon={<AddOutlinedIcon />}
            onClick={addLevel}
            data-element={elements.popup.advancedApprovalGroups.addLevelBtn}
          >
            {formatMessage({
              id: 'configurations.company.approvals.advancedGroups.form.addLevel',
              defaultMessage: 'Add level',
            })}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} data-element={elements.popup.advancedApprovalGroups.cancel}>
            {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            data-element={elements.popup.advancedApprovalGroups.save}
            loading={isSubmitting}
            disabled={!enableSubmit}
          >
            {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AdvancedGroupDialog;
