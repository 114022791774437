// @flow
import React from 'react';

import Tooltip from 'components/mui/Tooltip';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import type { UserEnhancedApprovalRecord } from 'domain/approvals/types';

type TProps = {
  maxAvatars?: string,
  approvers: Array<UserEnhancedApprovalRecord>,
};

const ApprovalsAvatarGroup: React$StatelessFunctionalComponent<TProps> = ({
  maxAvatars = 3,
  approvers,
  sx,
  ...rest
}) => (
  <AvatarGroup
    max={maxAvatars}
    sx={{
      '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
      '& .MuiAvatar-root:nth-of-type(1)': {
        zIndex: approvers.length,
      },
      ...sx,
    }}
    {...rest}
  >
    {approvers.map((a, j) => (
      <Tooltip key={a.id} t={a.fullName}>
        <Avatar src={a.picture} alt={a.fullName} sx={{ zIndex: j }} />
      </Tooltip>
    ))}
  </AvatarGroup>
);

export default ApprovalsAvatarGroup;
