// @flow
import React from 'react';
import type { RecordOf } from 'immutable';
import RouterNavLink from 'components/mui/Router/RouterNavLink';
import { useIntl } from 'react-intl';

import { Tabs as TabsMui } from '@mui/material';
import Tab from '@mui/material/Tab';

import CircleIcon from '@mui/icons-material/Circle';

type TTab = {
  to: string,
  id: string,
  defaultMessage: string,
  required?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  tabValue: string,
  Icon?: React$Node,
};

type TTabs = {
  tabs: Array<TTab>,
  value: string,
  orientation?: string,
  textAlign?: string,
};

type RequiredObjectType = {
  [key: string]: string,
};

export function requiredTab(obj: RecordOf<RequiredObjectType>, fields: Array<string>): boolean {
  return fields.some((key) => {
    const value = obj.get(key);
    return !value || value.length < 1;
  });
}

const Tabs: React$StatelessFunctionalComponent<TTabs> = ({
  value,
  tabs,
  orientation = 'horizontal',
  textAlign = 'center',
}) => {
  const { formatMessage } = useIntl();
  const availableTabs = tabs.filter(tab => !tab.hidden);

  return (
    <TabsMui value={value} sx={{ minHeight: 4 }} orientation={orientation}>
      {availableTabs.map(({ id, defaultMessage, to, tabValue, required, disabled, Icon }) => (
        <Tab
          sx={{
            flexDirection: 'row-reverse',
            minHeight: 2,
            textTransform: 'none',
            justifyContent: textAlign,
            textAlign: 'initial',
          }}
          key={id}
          value={tabValue}
          icon={required ? <CircleIcon sx={{ fontSize: 5, ml: 0.5 }} color="error" /> : ''}
          label={
            <>
              {formatMessage({ id, defaultMessage })}
              {Icon && <Icon sx={{ marginRight: 1 }} />}
            </>
          }
          component={RouterNavLink}
          disabled={disabled}
          href={to}
        />
      ))}
    </TabsMui>
  );
};

export default Tabs;
