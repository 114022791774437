import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';
import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

export const Fields = {
  name: 'name',
  levels: 'levels',
  approvers: 'approvers',
  minApprovers: 'minApprovers',
  minAmount: 'minAmount',
  flowType: 'flowType',
  owner: 'owner',
};

export const HeadersAdapter = ({ formatMessage, handleEditGroup, handleDeleteGroup }) => {
  const headers = [
    {
      field: Fields.name,
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.name',
        defaultMessage: 'Group Name',
      }),
      type: ColumnTypes.text,
      minWidth: 200,
      maxWidth: 450,
    },
    {
      field: Fields.levels,
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.approvers',
        defaultMessage: 'Approvers',
      }),
      cellRenderer: ColumnTypes.levels,
    },
    {
      field: 'date',
      headerName: formatMessage({
        id: 'configurations.company.approvals.groups.table.column.date',
        defaultMessage: 'Date',
      }),
      type: ColumnTypes.date,
    },
    {
      field: Fields.owner,
      headerName: formatMessage({
        id: 'configurations.company.approval_advancedGroups.table.column.owner',
        defaultMessage: 'Owner',
      }),
      cellRenderer: ColumnTypes.user,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: 'controls',
      cellRenderer: ColumnTypes.controls,
      width: 120,
      maxWidth: 120,
      cellRendererParams: {
        handleEditClick: handleEditGroup,
        handleDeleteClick: handleDeleteGroup,
      },
    },
  ];

  return headers;
};

const ApproverFactory = ({ name, levels, owner, ...rest }) => ({
  [Fields.name]: name,
  [Fields.levels]: levels,
  [Fields.owner]: owner,
  ...rest,
});

const RowFactory = (value) => ({
  ...ApproverFactory(value),
  [ColumnTypes.controls]: ApproverFactory(value),
  [ColumnTypes.date]: value.created_at,
});

const RowAdapter = (group, companyApprovers, orgUsers) =>
  RowFactory({
    ...group,
    levels: group.levels.map(({ approvers, ...rest }) => ({
      ...rest,
      approvers: approvers.reduce((a, v) => {
        const user = companyApprovers.find((u) => u.id === v);
        if (user)
          return [
            ...a,
            UserEnhancedApprovalFactory({
              userGuid: user.username,
              tag: user.username,
              id: user.id,
              picture: user.picture,
              fullName: user.fullName,
            }).toJS(),
          ];
        return a;
      }, []),
    })),
    owner: orgUsers.reduce((a, v) => {
      if (v.id === group.owner) {
        return UserEnhancedApprovalFactory({
          userGuid: v.email,
          tag: v.email,
          id: v.id,
          picture: v.pic,
          fullName: `${v.first_name} ${v.last_name}`,
        }).toJS();
      }
      return a;
    }, UserEnhancedApprovalFactory().toJS()),
    id: group.id,
  });

export const RowsAdapter = (groups, companyApprovers, orgUsers) =>
  groups.toJS().map((g) => RowAdapter(g, companyApprovers, orgUsers));
