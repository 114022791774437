import { useMemo } from 'react';
import { GRID_PAGE_SIZE, GRID_PER_PAGE_DEFAULT } from 'domain/documents/constants';
import useAgGridManualConfig from 'components/AgGrid/hooks/useAgGridManualConfig';

const useAgGridProps = () => {
  const { enableRtl, getLocaleText } = useAgGridManualConfig();

  return useMemo(
    () => ({
      debug: true,
      rowModelType: 'serverSide',
      maxBlocksInCache: 1,
      rowHeight: 72,
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      suppressServerSideFullWidthLoadingRow: true,
      suppressContextMenu: true,
      suppressScrollWhenPopupsAreOpen: true,
      columnMenu: 'legacy', // TODO: ag-grid review maybe migrate to new type menu 'columnMenu="new"'
      animateRows: false, // Row Animations can be a performance bottleneck
      // this is needed for tooltip,
      // columnHoverHighlight add .ag-column-hover on cell for finding hovered cell
      columnHoverHighlight: true,
      suppressMaxRenderedRowRestriction: true,
      suppressMenuHide: true,
      suppressScrollOnNewData: false,
      suppressColumnMoveAnimation: true,
      suppressMultiSort: true,
      suppressMoveWhenColumnDragging: true,
      suppressNavigable: true,
      suppressMovable: true,
      tooltipShowDelay: 0,
      scrollbarWidth: 8,
      alwaysShowHorizontalScroll: true,
      alwaysShowVerticalScroll: true,
      rowSelection: {
        mode: 'multiRow',
      },
      selectionColumnDef: {
        pinned: enableRtl ? 'right' : 'left',
        lockPosition: enableRtl ? 'right' : 'left',
        lockPinned: true,
      },
      defaultColDef: {
        filter: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
        resizable: true,
        minWidth: 120,
        flex: 1,
        headerComponentParams: {
          enablePinning: false,
        },
      },
      pagination: true,
      paginationPageSize: GRID_PER_PAGE_DEFAULT,
      paginationPageSizeSelector: GRID_PAGE_SIZE,
      // ====
      enableRtl,
      getLocaleText,
    }),
    [enableRtl, getLocaleText],
  );
};
export default useAgGridProps;
