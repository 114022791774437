import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import ToggleButtonGroupMui from '@mui/material/ToggleButtonGroup';

import { alpha } from '@mui/material';

export const SMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const MenuList = styled('ul')(() => ({ padding: 0 }));

export const ToggleButtonGroup = styled(ToggleButtonGroupMui)(({ theme }) => ({
  height: 56,
  padding: 4,
  border: `1px solid ${theme.palette.divider}`,
  width: 'fit-content',

  '& .MuiButtonBase-root': {
    borderRadius: 4,
    borderColor: 'transparent',
    '&.Mui-disabled': {
      borderColor: 'transparent',
    },
    '&.Mui-selected': {
      borderColor: theme.colors.primaryLight,
    },
    '&.Mui-disabled.Mui-selected': {
      borderColor: alpha(theme.colors.primaryLight, 0.3),
      color: alpha(theme.palette.primary.main, 0.3),
    },
  },
}));
