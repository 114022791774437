import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { getJournalEntryForGridAction } from 'domain/journal/actions';

import Table from 'components/Tables';
import LoadingError from 'components/Tables/Error';
import Spinner from 'components/mui/CircularProgressWithBackdrop';
import Box from '@mui/material/Box';

// eslint-disable-next-line react/prop-types
const MasterViewCellRenderer = ({ data: { documentID } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    new Promise((resolve, reject) => {
      setIsLoading(true);
      dispatch(getJournalEntryForGridAction({ documentID, resolve, reject }));
    }).catch(() => {
      setLoadingFailed(true);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, documentID]);

  const handleWheelCapture = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Box position="relative" width="100%" height="100%" sx={{ p: 1 }} onWheelCapture={handleWheelCapture}>
      <Spinner isOpen={isLoading} BackdropProps={{ sx: { position: 'absolute' } }} />
      {loadingFailed ? <LoadingError masterDetailView /> : <Table documentID={documentID} hasVisibleColumnsManageButton={false} />}
    </Box>
  );
};

export default MasterViewCellRenderer;
