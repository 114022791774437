// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { STable, STableRow, STableHeaderCell, STableCell } from './StyledComponents';
import DateCell from './components/DateCell';

import { ColumnTypes, createCellStyle, createRow } from './helpers';

import elements from 'components/elements';

const Table = ({ columnDefs, rowData, frameworkComponents }) => {
  const { formatMessage } = useIntl();
  const defaultCellRenderer = useCallback(({ value, header }) => {
    if (header.type === ColumnTypes.date) {
      return <DateCell value={value} />;
    }
    return <Typography variant="body2">{value}</Typography>;
  }, []);

  return (
    <TableContainer style={{ height: '100%' }}>
      <STable style={{ height: rowData?.length === 0 ? '100%' : 'fit-content' }}>
        <TableHead>
          <TableRow data-element={elements.configuration.company.approvals.table.headerRow}>
            {columnDefs.map((header) => (
              <STableHeaderCell key={header.field} style={createCellStyle(header)}>
                {header.headerName}
              </STableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((r) => (
            <STableRow key={r.id} data-element={elements.configuration.company.approvals.table.row}>
              {createRow({
                rowData: r,
                columnDefs,
                renderCell: ({ header, value, key, ...rest }) => (
                  <STableCell
                    key={key}
                    style={createCellStyle(header)}
                    data-element={`${elements.configuration.company.approvals.table.cell}.${key}`}
                  >
                    {frameworkComponents[header.cellRenderer]?.({ ...header.cellRendererParams, value }) ||
                      defaultCellRenderer({ value, ...rest, header })}
                  </STableCell>
                ),
              })}
            </STableRow>
          ))}
          {rowData?.length === 0 && (
            <STableRow>
              <STableCell colSpan={columnDefs.length}>
                {formatMessage({ id: 'company.workspace.grid.noRowsToShow', defaultMessage: 'No Rows To Show' })}
              </STableCell>
            </STableRow>
          )}
        </TableBody>
      </STable>
    </TableContainer>
  );
};

export default Table;
