import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  sortOptions: {
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },

  sortIcon: {
    padding: 0,
    marginLeft: -2,
  },

  active: {
    borderBottomColor: theme.colors.greyDarkest,
  },

  menuButton: {
    marginLeft: 10,
  },

  headerButton: {
    '&:before': { fontSize: 18 },
  },

  activeButton: {
    color: theme.palette.primary.main,
  },
}));
