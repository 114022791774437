import { buildFilterMapping } from 'components/AgGrid/helpers/filterHelpers';

const filterMappingByColumnType = [
  {
    string: { filter: 'agTextColumnFilter' },
  },
  {
    boolean: { filter: 'customSetColumnFilter' },
  },
  {
    select: { filter: 'customSetColumnFilter' },
  },
  {
    multi_select: { filter: 'customSetColumnFilter' },
  },
  {
    advanced_approval_set: { filter: 'agTextColumnFilter' },
  },
];

export const filterMapping = buildFilterMapping(filterMappingByColumnType);
