import { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { debounce } from '@mui/material/utils';
import { getComparator } from 'pages/configurations/helpers';

const useSearchAndSort = ({ generateStringForSearch, initialList, defaultSortFn }) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('default');
  const [sortBy, setSortBy] = useState(null);
  const [rows, setRows] = useState([]); // current sorted user list
  const rowsRef = useRef([]); // copy current unsorted user list
  const listRef = useRef([]); // contain all users with applied sorting - used for search

  useEffect(() => {
    const data = defaultSortFn(initialList);

    setRows(data);
    rowsRef.current = data.slice();
    listRef.current = data.slice();
  }, [initialList, defaultSortFn]);

  useEffect(() => {
    if (searchText) {
      const data = listRef.current.filter((item) => {
        const string = generateStringForSearch(item);
        const isExist = string.toLowerCase().indexOf(searchText.toLowerCase().trimStart());

        return isExist > -1;
      });

      setRows(data);
      rowsRef.current = data;
    } else {
      setRows(listRef.current);
      rowsRef.current = listRef.current.slice();
    }
  }, [searchText, generateStringForSearch]);

  useEffect(() => {
    if (sort !== 'default') {
      const comparator = getComparator(sort, sortBy);
      const sortedRows = rowsRef.current.slice().sort(comparator);
      listRef.current = listRef.current.slice().sort(comparator);

      setRows(sortedRows);
    } else {
      setRows(defaultSortFn(rowsRef.current));
      listRef.current = defaultSortFn(listRef.current);
    }
  }, [sort, sortBy, defaultSortFn]);

  const onChangeSearch = useCallback((text: string) => setSearchText(text), []);

  const debounceOnChangeSearch = useMemo(() => debounce(onChangeSearch, 250), [onChangeSearch]);

  const handleChangeSort = useCallback(
    (columnID) => {
      if (sortBy === columnID) {
        switch (sort) {
          case 'asc':
            setSort('desc');
            break;
          case 'desc':
            setSort('default');
            break;
          default:
            setSort('asc');
        }
      } else {
        setSort('asc');
        setSortBy(columnID);
      }
    },
    [sortBy, sort],
  );

  return {
    handleChangeSort,
    debounceOnChangeSearch,
    rows,
    searchText,
    sort,
    sortBy,
  };
};

export default useSearchAndSort;
