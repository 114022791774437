// @flow
import React, { useCallback } from 'react';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

import Autocomplete from '@mui/material/Autocomplete';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import Avatar from 'components/mui/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

type Props = {
  emailOptions: Array<string>,
  approvers: { [username: string]: { picture: string, fullName: string } },
  label?: string,
  meta: $PropertyType<FieldProps, 'meta'>,
  input: {
    value: string,
    name: string,
    onChange: (value: string) => void,
    onBlur: (value: string) => void,
  },
};

const UserField: React$StatelessFunctionalComponent<Props> = ({
  emailOptions,
  approvers,
  input: { value, onChange, onBlur, ...inputRest },
  label: inputLabel,
  meta,
  ...rest
}) => {
  const renderOption = useCallback(
    (props, username) => (
      <Stack direction="row" alignItems="center" gap={1} {...props}>
        <Box>
          <Avatar src={approvers[username]?.picture} />
        </Box>
        <Box>
          <Typography variant="body2">
            {approvers[username] ? `${approvers[username].fullName} (${username})` : username}
          </Typography>
        </Box>
      </Stack>
    ),
    [approvers],
  );

  const renderTags = useCallback(
    (values, getTagProps) =>
      values.map((username, index) => (
        <Chip
          key={username}
          avatar={
            <Avatar
              alt={approvers[username]?.fullName || username}
              src={approvers[username]?.picture}
              size={24}
              sx={{ ml: 0.5 }}
            />
          }
          variant="outlined"
          label={approvers[username]?.fullName || username}
          color="primary"
          {...getTagProps({ index })}
        />
      )),
    [approvers],
  );

  return (
    <Autocomplete
      {...rest}
      multiple
      autoHighlight
      id={inputLabel}
      value={value || []}
      clearIcon={null}
      onChange={(event, newValue) => onChange(newValue.slice(-1))}
      onBlur={() => {
        onBlur(value);
      }}
      options={emailOptions}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionLabel={(option) =>
        approvers[option] ? `${approvers[option].fullName} ${approvers[option].username}` : option
      }
      renderInput={(params) => <TextFieldForm {...params} {...rest} input={inputRest} label={inputLabel} meta={meta} />}
    />
  );
};

export default UserField;
