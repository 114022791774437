export const createCellStyle = ({ width, maxWidth, minWidth }) => ({
  width,
  maxWidth,
  minWidth,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
});

export const ColumnTypes = {
  text: 'text',
  controls: 'controls',
  approver: 'approver',
  approvals: 'approvals',
  levels: 'levels',
  user: 'user',
  date: 'date',
};

export const createRow = ({ rowData, columnDefs, renderCell }) =>
  Object.entries(rowData)
    .filter(([key]) => columnDefs.find((c) => c.field === key))
    .sort((a, b) => columnDefs.findIndex((i) => i.field === a[0]) - columnDefs.findIndex((i) => i.field === b[0]))
    .map(([key, value]) => {
      const header = columnDefs.find((c) => c.field === key);
      return renderCell({ header, value, key });
    });
