/* @flow */
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { isDocumentScheduledApprovalSelector } from 'domain/documents';
import { approveApprovalAction } from 'domain/approvals/actions';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';
import { userFeaturesSelector } from 'domain/env';
import { useConfirm } from 'material-ui-confirm';
import useRedirectWhenApprovalsEnd from 'hooks/approval/useRedirectWhenApprovalsEnd';
import * as ACL from 'domain/restriction';

import Button from '@mui/material/Button';

type TApprovalButtons = {|
  onManageApprovals: () => void,
  onOpenApproveForm: () => void,
  onOpenRejectForm: () => void,
|};

const mapStateToProps = (state) => ({
  companyFeatureSet: companyFeatureSetSelector(state),
  userFeatures: userFeaturesSelector(state),
  isScheduledApproval: isDocumentScheduledApprovalSelector(state),
  isGranted: ACL.isGranted(state),
});

const ApprovalButtons: React$StatelessFunctionalComponent<TApprovalButtons> = ({
  onManageApprovals,
  onOpenApproveForm,
  onOpenRejectForm,
}) => {
  const dispatch = useDispatch();
  const { redirectToApprove, isAllowRedirectAfterApprove: isAllowRedirect } = useRedirectWhenApprovalsEnd();
  const { companyFeatureSet, userFeatures, isScheduledApproval, isGranted } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const confirm = useConfirm();
  const isApprovalFormEnable = companyFeatureSet.approvals_form;
  const isAllowAutoConfirm = !userFeatures.get('modal', true);
  const isAccount = isGranted(ACL.IS_ACCOUNT);

  const handleApprove = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(approveApprovalAction({ resolve, reject }));
    }).then(() => (isAllowRedirect ? redirectToApprove() : onManageApprovals()));
  }, [dispatch, isAllowRedirect, redirectToApprove, onManageApprovals]);

  const handleApproveClick = useCallback(() => {
    if (isApprovalFormEnable && !isAccount) {
      onOpenApproveForm();
    } else if (isAllowAutoConfirm) {
      handleApprove();
    } else {
      confirm({
        title: formatMessage({
          id: 'documents.approval.confirmation.title',
          defaultMessage: 'Approve Document',
        }),
        description: formatMessage({
          id: 'documents.approval.confirmation.placeholder',
          defaultMessage: 'Are you sure?',
        }),
        confirmationText: formatMessage({
          id: 'document.approval.approve',
          defaultMessage: 'Approve',
        }),
      })
        .then(() => {
          handleApprove();
        })
        .catch(() => {});
    }
  }, [isApprovalFormEnable, onOpenApproveForm, isAllowAutoConfirm, handleApprove, confirm, formatMessage, isAccount]);

  return (
    <>
      <Button variant="outlined" color="error" onClick={onOpenRejectForm} disabled={isScheduledApproval}>
        {formatMessage({
          id: 'document.approval.reject',
          defaultMessage: 'Reject',
        })}
      </Button>
      <Button variant="contained" color="secondary" onClick={handleApproveClick}>
        {formatMessage({
          id: 'document.approval.approve',
          defaultMessage: 'Approve',
        })}
      </Button>
    </>
  );
};

export default ApprovalButtons;
