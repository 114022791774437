import { buildFilterMapping } from 'components/AgGrid/helpers/filterHelpers';

const filterMappingByColumnType = [
  {
    string: { filter: 'agTextColumnFilter' },
  },
  {
    number: { filter: 'agNumberColumnFilter' },
  },
  {
    integer: { filter: 'agNumberColumnFilter' },
  },
  {
    date: { filter: 'agDateColumnFilter' },
  },
  {
    boolean: { filter: 'booleanColumnFilter' },
  },
  {
    tag: { filter: 'agTextColumnFilter' },
  },
  {
    tag_set: { filter: 'customSetColumnFilter' },
  },
  {
    approval: { filter: 'agTextColumnFilter' },
  },
  {
    approval_set: { filter: 'customSetColumnFilter' },
  },
  {
    amount: { filter: 'agNumberColumnFilter' },
  },
  {
    select: { filter: 'customSetColumnFilter' },
  },
  {
    static_select: { filter: 'customSetColumnFilter' },
  },
];

export const filterMapping = buildFilterMapping(filterMappingByColumnType);
