// @flow
import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import { deleteApproverAction } from 'domain/approvals/actions';
import { approversSelector } from 'domain/approvals/selectors';

import useToggle from 'hooks/useToggle';

import EmptyScreen from 'pages/configurations/company/pages/approvals/components/EmptyScreen';
import AddApproverDialog from './components/AddApproverDialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from 'pages/configurations/company/pages/approvals/components/Table';
import ControlsCell from 'pages/configurations/company/pages/approvals/components/Table/components/ControlsCell';
import ApproverCell from 'pages/configurations/company/pages/approvals/components/Table/components/ApproverCell';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { HeadersAdapter, RowsAdapter } from './helpers';
import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

import elements from 'components/elements';
import { debounce } from '@mui/material/utils';

const mapStateToProps = (state) => ({
  approvers: approversSelector(state),
});

const ListOfApprovers = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const { approvers } = useSelector(mapStateToProps);
  const [openModal, onToggleModal] = useToggle(false);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const initialRows = useRef([]);

  const onChangeSearch = useCallback((text: string) => setSearchText(text), []);

  const debounceOnChangeSearch = useMemo(() => debounce(onChangeSearch, 250), [onChangeSearch]);

  const adaptedData = useMemo(() => RowsAdapter(approvers), [approvers]);

  const generateStringForSearch = useCallback(
    ({ approver }) => `${approver.username} ${approver.fullName} ${approver.phone}`,
    [],
  );

  const handleDeleteApprover = useCallback(
    (approverId) => {
      dispatch(deleteApproverAction({ approverId }));
    },
    [dispatch],
  );

  const onDeleteApproverClick = useCallback(
    (approver) => {
      confirm({
        title: formatMessage(
          {
            id: 'configurations.company.approvals.deleteApproverModal.title',
            defaultMessage: `Remove ${approver.fullName}`,
          },
          { approver: approver.fullName },
        ),
        description: formatMessage(
          {
            id: 'configurations.company.approvals.deleteApproverModal.body',
            defaultMessage: `${approver.fullName} will be removed. This will not affect existing approval flows`,
          },
          { approver: approver.fullName },
        ),
        confirmationText: formatMessage({
          id: 'button.remove',
          defaultMessage: 'Remove',
        }),
        confirmationButtonProps: { color: 'error' },
        cancellationText: formatMessage({
          id: 'button.Cancel',
          defaultMessage: 'Cancel',
        }),
      })
        .then(() => {
          handleDeleteApprover(approver.id);
        })
        .catch(() => {});
    },
    [confirm, formatMessage, handleDeleteApprover],
  );

  const columnDefs = useMemo(
    () => HeadersAdapter({ formatMessage, handleDeleteApprover: onDeleteApproverClick }),
    [formatMessage, onDeleteApproverClick],
  );

  const frameworkComponents = useMemo(
    () => ({
      [ColumnTypes.controls]: ControlsCell,
      [ColumnTypes.approver]: ApproverCell,
    }),
    [],
  );

  useEffect(() => {
    setRows(adaptedData);
    initialRows.current = adaptedData.slice();
  }, [adaptedData]);

  useEffect(() => {
    if (searchText) {
      const data = initialRows.current.filter((item) => {
        const string = generateStringForSearch(item);
        const isExist = string.toLowerCase().indexOf(searchText.toLowerCase().trimStart());

        return isExist > -1;
      });

      setRows(data);
    } else {
      setRows(initialRows.current);
    }
  }, [searchText, generateStringForSearch]);

  return (
    <>
      {adaptedData?.length ? (
        <>
          <Box display="flex" mb={2}>
            <Typography variant="subtitle1" fontWeight={500}>
              {formatMessage({
                id: 'configurations.company.approvals.list.title',
                defaultMessage: 'Approvers list',
              })}
            </Typography>
            <Button
              type="button"
              variant="outlined"
              startIcon={<AddOutlinedIcon />}
              onClick={onToggleModal}
              sx={{ ml: 'auto' }}
              data-element={elements.configuration.company.approvals.list.createApproverButton}
            >
              {formatMessage({ id: 'configurations.company.addApproverBtn', defaultMessage: 'Add approver' })}
            </Button>
          </Box>

          <SearchBase value={searchText} onChange={debounceOnChangeSearch} />

          <Box data-element={elements.configuration.company.approvals.list.approverList} style={{ height: '100%' }}>
            <Table columnDefs={columnDefs} rowData={rows} frameworkComponents={frameworkComponents} />
          </Box>
        </>
      ) : (
        <EmptyScreen
          title={formatMessage({
            id: 'configurations.company.approvals.list.emptyList.title',
            defaultMessage: 'No approvers added',
          })}
          description={formatMessage({
            id: 'configurations.company.approvals.list.emptyList.description',
            defaultMessage:
              'You haven’t added any approvers yet. Start building your approval flow by adding approvers',
          })}
          buttonText={formatMessage({ id: 'configurations.company.addApproverBtn', defaultMessage: 'Add Approver' })}
          onClick={onToggleModal}
        />
      )}
      {openModal && (
        <AddApproverDialog
          open={openModal}
          handleClose={onToggleModal}
          title={formatMessage({
            id: 'configurations.company.approvals.list.dialog.addApprover.title',
            defaultMessage: 'Add Approver',
          })}
        />
      )}
    </>
  );
};

export default ListOfApprovers;
