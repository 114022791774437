// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from 'components/mui/Tooltip';

import type { UserEnhancedApproval } from 'domain/approvals/types';

type TUserCell = {
  value: UserEnhancedApproval,
};

const UserCell: React$StatelessFunctionalComponent<TUserCell> = ({ value }) => (
  <Tooltip key={value.id} t={value.tag}>
    <Stack direction="row" alignItems="center" gap={1} maxWidth="fit-content">
      <Avatar alt={value.fullName} src={value.picture} sx={{ height: 24, width: 24 }} />
      <Typography variant="body2">{value.fullName}</Typography>
    </Stack>
  </Tooltip>
);

export default UserCell;
