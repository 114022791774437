const tooltipComponentByType = {
  select: 'customTooltip',
};

const getTooltipProps = (column) => {
  const tooltipComponent = tooltipComponentByType[column.type];

  return tooltipComponent
    ? {
        tooltipComponent,
        tooltipField: column.field,
      }
    : {};
};

const getHeaderTooltipProps = ({ headerComponentParams }) =>
  headerComponentParams
    ? { headerComponentParams: { ...headerComponentParams, headerTooltip: true } }
    : { headerComponentParams: { headerTooltip: true } };

export const addTooltipPropsForColDefs = (columns) =>
  columns.map((column) => ({
    ...column,
    ...getTooltipProps(column),
  }));

export const addHeaderTooltipPropsForColDefs = (columns) =>
  columns.map((column) => ({
    ...column,
    ...getHeaderTooltipProps(column),
  }));

