import TextFieldMui from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const TextField = styled(TextFieldMui, { label: 'AutocompleteTextField' })(({ theme }) => ({
  padding: theme.spacing(1, 0.5),

  '& .MuiInputBase-root': {
    paddingLeft: theme.spacing(1),
  },

  '& .MuiInputBase-input': {
    fontSize: theme.typography.pxToRem(13),
    marginTop: theme.spacing(0.5),
  },
}));

export const StyledListbox = styled('ul')({
  maxHeight: '200px !important',
  overflowY: 'auto',
});

export const MenuList = styled('ul')(() => ({ padding: 0 }));
